import { AxiosResponse } from 'axios';
import { api } from './axios';

// ----------------------------------------------------------------------

const cardsEndpoints = {
  cards: '/post-paid-cards',
};

export async function createNewClientCard({ type, userId }: { type: string; userId: string }) {
  try {
    const payload: object = {
      type,
      userId,
    };

    const response = await api.post(cardsEndpoints.cards, payload);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function updateCardById({ original, id, blocked }: { original: any; id: string; blocked: boolean }) {
  try {
    const payload: object = { ...original, blocked };

    const response: void | AxiosResponse<any> = await api.put(`${cardsEndpoints.cards}/${id}`, payload);
    return response?.data;
  } catch (error) {
    throw new Error(error.message);
  }
}
