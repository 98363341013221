import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, Button, Grid, Typography } from '@material-ui/core';

import { PostPaidCardType } from './PostPaidCardModel';

const useStyles = makeStyles({
  card: {
    width: 86 * 4,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
    color: '#fff',
  },
  cardContent: {
    height: 55 * 3,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  backText: {
    color: '#fff',
    paddingBottom: '8',
    fontWeight: 200,
    fontSize: '0.8rem',
  },
});

export default function PostPaidCard(props) {
  const classes = useStyles();
  const postPaidCardType = props.props.type;

  return (
    <Card
      className={classes.card}
      style={{
        backgroundColor: postPaidCardType !== PostPaidCardType.POST_PAID ? '#AA00FF' : '#00838F',
      }}
    >
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography
              style={{
                color: '#fff',
                fontSize: '1.2rem',
              }}
            >
              Cartão {props.props.placeName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.backText}>TIPO</div>
            Pós-pago
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.backText}>SALDO</div>
            R$ 0,00
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button>Exibir</Button>
        <Button>Adicionar Crédito</Button>
      </CardActions>
    </Card>
  );
}
