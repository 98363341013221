import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Box, FormControl, TextField } from '@material-ui/core';
// components
import { RootState } from '../../redux/store';
import { updateFilterOptions, resetPaginationData } from '../../redux/slices/cards';

import { FilterButtons } from '../library/FilterButtons';

// ----------------------------------------------------------------------

export function CardsFormFilter() {
  const dispatch = useDispatch();
  const [clientName, setClientName] = useState('');
  const [filterRequesting, setFilterRequesting] = useState(false);
  const [clearRequesting, setClearRequesting] = useState(false);
  const { isRequestingCards } = useSelector((state: RootState) => state.cards);

  const { reset } = useSelector((state: RootState) => state.global);

  const handleName = (event) => {
    const { value } = event.target;
    setClientName(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (clientName !== '') {
      dispatch(resetPaginationData(true));
      dispatch(updateFilterOptions(clientName));
      setFilterRequesting(true);
    }
  };

  useEffect(() => {
    if (reset === true) {
      setClientName('');
      setFilterRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (isRequestingCards === false) {
      setFilterRequesting(false);
      setClearRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingCards]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <form
        id="cardsForm"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
        onSubmit={(event) => handleSubmit(event)}
      >
        <FormControl sx={{ marginRight: 2 }}>
          <TextField
            name="name"
            id="name"
            label="Nome do cliente"
            type="text"
            value={clientName}
            variant="outlined"
            onChange={(event) => handleName(event)}
          />
        </FormControl>
        <FilterButtons
          initialState=""
          filterRequesting={filterRequesting}
          clearRequesting={clearRequesting}
          setFilter={setClientName}
          setClearRequesting={setClearRequesting}
          formId="cardsForm"
          updateFilter={updateFilterOptions}
          resetPagination={resetPaginationData}
        />
      </form>
    </Box>
  );
}
