import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../utils/axios';
import { MessageString } from '../../@types/messages';
import { store } from '../store';

const cardsEndpoints = {
  report: '/post-paid-cards/report',
  cards: '/post-paid-cards',
  users: '/users',
  credits: '/post-paid-credits',
};

type CardsStates = {
  isRequestingCards: boolean;
  errorMessageCards: string;
  isRequestingCard: boolean;
  errorMessageCard: string;
  isRequestingReport: boolean;
  errorMessageReport: string;
  isRequestingUsers: boolean;
  isRequestingUsersAutoComplete: boolean;
  errorMessageUsers: string;
  isRequestingUser: boolean;
  errorMessageUser: string;
  user: any;
  users: any;
  cards: any;
  card: any;
  _order: string;
  _end: number;
  _start: number;
  report: any;
  headerCount: number;
  updated: boolean;
  resetPagination: boolean;
  late: boolean;
  name: string;
};

const initialState: CardsStates = {
  isRequestingCards: false,
  errorMessageCards: '',
  isRequestingCard: false,
  errorMessageCard: '',
  isRequestingReport: false,
  errorMessageReport: '',
  isRequestingUsers: false,
  isRequestingUsersAutoComplete: false,
  errorMessageUsers: '',
  isRequestingUser: false,
  errorMessageUser: '',
  user: {},
  users: [],
  cards: [],
  card: {},
  _order: 'DESC',
  _end: 10,
  _start: 0,
  report: {},
  headerCount: 0,
  updated: false,
  resetPagination: false,
  late: false,
  name: '',
};

const slice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    requestingCards(state) {
      state.isRequestingCards = true;
    },
    getCardsSuccess(state, action) {
      state.isRequestingCards = false;
      state.cards = action.payload;
    },
    getCardsFail(state, action) {
      state.isRequestingCards = false;
      state.errorMessageCards = action.payload;
    },
    requestingCredit(state) {
      state.isRequestingCard = true;
    },
    finishRequestCredit(state) {
      state.isRequestingCard = false;
    },
    getCardSuccess(state, action) {
      state.isRequestingCard = false;
      state.card = action.payload;
    },
    getCardFail(state, action) {
      state.isRequestingCard = false;
      state.errorMessageCard = action.payload;
    },
    getOrder(state, action) {
      state._order = action.payload;
    },
    getPaginationStartAndEnd(state, action) {
      const { start, end } = action.payload;
      state._end = end;
      start._start = start;
    },
    getCardsHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetPermissions(state, action) {
      state.updated = action.payload;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
    requestingReport(state) {
      state.isRequestingReport = true;
    },
    getReportSuccess(state, action) {
      state.isRequestingReport = false;
      state.report = action.payload;
    },
    getReportFail(state, action) {
      state.isRequestingReport = false;
      state.errorMessageReport = action.payload;
    },
    requestingUsers(state) {
      state.isRequestingUsers = true;
    },
    getUsersSuccess(state, action) {
      state.isRequestingUsers = false;
      state.users = action.payload;
    },
    getUsersFail(state, action) {
      state.isRequestingUsers = false;
      state.errorMessageUsers = action.payload;
    },
    requestingUser(state) {
      state.isRequestingUser = true;
    },
    getUserSuccess(state, action) {
      state.isRequestingUser = false;
      state.user = action.payload;
    },
    getUserFail(state, action) {
      state.isRequestingUser = false;
      state.errorMessageUser = action.payload;
    },
    requestingUsersAutoComplete(state) {
      state.isRequestingUsersAutoComplete = true;
    },
    getUsersAutoCompleteSuccess(state, action) {
      state.isRequestingUsersAutoComplete = false;
      state.users = action.payload;
    },
    getUsersAutoCompleteFail(state, action) {
      state.isRequestingUsersAutoComplete = false;
      state.errorMessageUsers = action.payload;
    },
    getFilters(state, action) {
      state.name = action.payload;
    },
    getLatePayments(state, action) {
      state.late = action.payload;
    },
    resetAllStates(state) {
      state.users = [];
      state.user = {};
      state.cards = [];
      state.card = {};
      state.name = '';
    },
  },
});

export default slice.reducer;

export function getCardsByPlace({
  _end,
  _order,
  _sort,
  _start,
  q,
  late,
  user,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  q: string;
  late: boolean;
  user: string;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingCards());
    dispatch(slice.actions.resetPagination(false));

    const queryStrings: any = {
      _end,
      _order,
      _sort,
      _start,
    };
    const minLength = 1;
    q.length > minLength && (queryStrings.q = q);
    late !== false && (queryStrings.late = late);
    user !== '' && (queryStrings.userId = user);

    await api
      .get(cardsEndpoints.cards, {
        params: queryStrings,
      })
      .then((response) => {
        dispatch(slice.actions.getCardsSuccess(response?.data));
        dispatch(slice.actions.getCardsHeaders(Number(response?.headers['x-total-count'])));
      })
      .catch((error) => {
        if (error.message) {
          throw new Error(error.message as MessageString);
        }
      });
  };
}

export function getReport() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingReport());
    dispatch(slice.actions.resetPagination(false));
    await api
      .get(cardsEndpoints.report)
      .then((response) => {
        dispatch(slice.actions.getReportSuccess(response?.data));
      })
      .catch((error) => {
        if (error.message) {
          throw new Error(error.message as MessageString);
        }
      });
  };
}

export function getCardById(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingCredit());
    await api
      .get(`${cardsEndpoints.cards}/${id}`)
      .then((response) => {
        dispatch(slice.actions.getCardSuccess(response?.data));
      })
      .catch((error) => {
        if (error.message) {
          dispatch(slice.actions.finishRequestCredit());
          throw new Error(error.message as MessageString);
        }
      });
  };
}

export function getUserById(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingUser());
    const config = {
      params: { id },
    };
    await api
      .get(cardsEndpoints.users, config)
      .then((response) => {
        const [user] = response?.data;
        dispatch(slice.actions.getUserSuccess(user));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(slice.actions.getUserFail(error.message));
          throw new Error(error.message as MessageString);
        }
      });
  };
}

export function getUsersByPlace({
  _end,
  _order,
  _sort,
  _start,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingUsers());

    const config = {
      params: {
        _end,
        _order,
        _sort,
        _start,
      },
    };

    api
      .get(cardsEndpoints.users, config)
      .then((response) => {
        const users = response?.data;
        dispatch(slice.actions.getUsersSuccess(users));
      })
      .catch((error) => {
        if (error.message) {
          dispatch(slice.actions.getUsersFail(error.message));
          throw new Error(error?.message as MessageString);
        }
      });
  };
}

export function getUsersByName({ q }: { q: string }) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingUsersAutoComplete());

    const config = {
      params: {
        q,
        _end: 100,
      },
    };

    await api
      .get(cardsEndpoints.users, config)
      .then((response) => {
        const users = response?.data;
        dispatch(slice.actions.getUsersSuccess(users));
      })
      .catch((error) => {
        dispatch(slice.actions.getUsersFail(error.message));
        if (error.response) {
          throw new Error(error?.message as MessageString);
        }
      });
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.getOrder(order));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}

export function updateFilterOptions(name: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getFilters(name));
  };
}

export function getLatePaymentsState(state: boolean) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getLatePayments(state));
  };
}

export function resetCardsStates() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetAllStates());
  };
}
