import { useEffect } from 'react';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CreditsDataList } from '../_credits/CreditsDataList';
import { getUserById } from '../../redux/slices/cards';
import { getCreditsByPlace } from '../../redux/slices/credits';
import { RootState } from '../../redux/store';
import { resetDataList } from '../../redux/slices/global';

// ----------------------------------------------------------------------

export function ClientsPanelCredits() {
  const dispatch = useDispatch();
  const { _end, _order, _start } = useSelector((state: RootState) => state.credits);
  const { user } = useSelector((state: RootState) => state.cards);
  const { reset } = useSelector((state: RootState) => state.global);

  useEffect(() => {
    dispatch(getUserById(JSON.parse(localStorage.getItem('user')!)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sort = 'createdAt';
    dispatch(
      getCreditsByPlace({
        _end,
        _order,
        _sort: sort,
        _start,
        userId: '',
        user: JSON.parse(localStorage.getItem('user')!),
        period: 'ALL',
        type: 'ALL',
        cpf: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_end, _order]);

  useEffect(() => {
    if (reset === true) {
      const sort = 'createdAt';
      dispatch(
        getCreditsByPlace({
          _end,
          _order,
          _sort: sort,
          _start,
          userId: '',
          user: user.id,
          period: 'ALL',
          type: 'ALL',
          cpf: '',
        })
      );
      dispatch(resetDataList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Card sx={{ p: 4 }}>
      <CreditsDataList view="cards" />
    </Card>
  );
}
