import { Icon } from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import baselineDownload from '@iconify/icons-ic/baseline-download';
import CsvDownloader from 'react-csv-downloader';

const useStyles = makeStyles(() => ({
  formButton: {
    margin: 0,
  },
}));

type ExportButtonTypes = {
  handleExport: () => any;
  isRequesting: boolean;
  name: string;
};

export function ExportButton({ handleExport, isRequesting, name }: ExportButtonTypes) {
  const classes = useStyles();

  return (
    <CsvDownloader
      filename={name}
      extension=".csv"
      separator=";"
      wrapColumnChar=""
      text="DOWNLOAD"
      datas={handleExport}
    >
      <LoadingButton
        className={classes.formButton}
        size="medium"
        variant="contained"
        type="button"
        startIcon={<Icon icon={baselineDownload} />}
        pending={isRequesting}
      >
        Exportar
      </LoadingButton>
    </CsvDownloader>
  );
}
