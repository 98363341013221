import { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardById } from '../../utils/cardsServices';
import { deleteDeviceById } from '../../utils/devicesServices';
import { resetDataList } from '../../redux/slices/global';
import { RootState } from '../../redux/store';

type DialogStates = {
  openDialog: any;
  setOpenDialog(state: boolean): void;
  type: string;
};

const messageOptions = {
  blockCard: ['Bloquear cartão', 'Essa ação pode ser revertida'],
  unlockCard: ['Desbloquear cartão', 'Essa ação pode ser revertida'],
  deleteDevice: ['Excluir dispositivo', 'Você tem certeza que deseja excluir?'],
};

const titleIndex = 0;
const descriptionIndex = 1;

export function ActionDialog({ openDialog, setOpenDialog, type }: DialogStates) {
  const dispatch = useDispatch();
  const { card, isRequestingCard } = useSelector((state: RootState) => state.cards);
  const [requestingSave, setRequestingSave] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog({ ...openDialog, status: false });
  };

  const handleSave = async () => {
    setRequestingSave(true);
    try {
      const response =
        type === 'deleteDevice'
          ? await deleteDeviceById(openDialog.id)
          : await updateCardById({ original: card, id: card.id, blocked: !card.blocked });
      if (response) {
        setOpenDialog({ ...openDialog, status: false });
        setRequestingSave(false);
        void dispatch(resetDataList(true));
        enqueueSnackbar(`${type === 'deleteDevice' ? 'Dispositivo excluído' : 'Cartão bloqueado'} com sucesso`, {
          variant: 'success',
        });
      }
    } catch (error) {
      setRequestingSave(false);
      enqueueSnackbar(
        `${
          type === 'deleteDevice' ? 'Não foi possível excluir o dispositivo' : 'Não foi possível desbloquear ocartão'
        }`,
        {
          variant: 'error',
        }
      );
    }
  };

  useEffect(() => {
    if (isRequestingCard === false) {
      setRequestingSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingCard]);

  return (
    <Dialog open={openDialog.status} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Box>
        <DialogTitle id="form-dialog-title">{`${messageOptions[type][titleIndex]} ${
          type === 'deleteDevice' ? openDialog.id : ''
        }`}</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText sx={{ mb: 4 }}>{messageOptions[type][descriptionIndex]}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <LoadingButton variant="contained" onClick={handleSave} color="primary" pending={requestingSave}>
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
