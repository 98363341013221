import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  FormControl,
  TextField,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Stack,
  Autocomplete,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { PATH_DASHBOARD } from '../../routes/paths';
import { RootState } from '../../redux/store';
import { createNewClientCard } from '../../utils/cardsServices';
import { getUsersByName } from '../../redux/slices/cards';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  formButton: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export function CardsCreateForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [userOptions, setUserOptions] = useState({
    cardType: 'POST_PAID',
    client: '',
  });
  const [clientList, setClientList]: any[] = useState([]);
  const [requestingCreate, setRequestingCreate] = useState(false);
  const { users } = useSelector((state: RootState) => state.cards);

  const { enqueueSnackbar } = useSnackbar();

  const handleNewUserCard = async () => {
    setRequestingCreate(true);
    const { userId } = clientList.find((person) => person.name === userOptions.client);
    try {
      await createNewClientCard({ type: userOptions.cardType, userId });
      enqueueSnackbar('Cartão adicionado com sucesso', { variant: 'success' });
    } catch (error) {
      setRequestingCreate(false);
      enqueueSnackbar('Não foi possível adicionar o cartão', { variant: 'error' });
    }
  };

  const searchUserByName = (event: any) => {
    dispatch(getUsersByName({ q: event.target.value }));
  };

  const handleChange = (event, name) => {
    const {
      type,
      target: { innerText, value },
    } = event;
    const eventValue = type === 'click' ? innerText : value;
    setUserOptions({ ...userOptions, [name]: eventValue });
  };

  const handleBackCardsPage = () => {
    history.push(PATH_DASHBOARD.cards.root);
  };

  // const handleClose = () => {
  //   setSnackbarMessage('');
  //   if (snackbarMessage === 'success') {
  //     history.push(PATH_DASHBOARD.cards.root);
  //   }
  // };

  useEffect(() => {
    const formattedList = users.map((option) => {
      const { firstName, lastName } = option;
      return {
        name: `${firstName} ${lastName}`,
        userId: option.id,
      };
    });
    setClientList(formattedList.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)));
  }, [users]);

  return (
    <Box sx={{ mb: 1, mt: 1, display: 'flex' }}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Stack spacing={2} sx={{ width: 300, my: 4, mx: 1 }}>
          <Autocomplete
            id="client"
            disableClearable={true}
            onKeyUp={(event) => searchUserByName(event)}
            options={clientList.map((client) => client.name)}
            onChange={(event) => handleChange(event, 'client')}
            renderInput={(params) => <TextField {...params} label="Nome do cliente" />}
          />
        </Stack>
        <FormControl component="fieldset" sx={{ mx: 1 }}>
          <FormLabel component="legend">Tipo de cartão</FormLabel>
          <RadioGroup
            aria-label="card"
            name="cardType"
            value={userOptions.cardType}
            onChange={(event) => handleChange(event, 'cardType')}
          >
            <FormControlLabel value="POST_PAID" control={<Radio />} label="Pós-pago" />
            <FormControlLabel value="PRE_PAID" control={<Radio />} label="Pré-pago" />
          </RadioGroup>
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 3,
          }}
        >
          <Button
            type="button"
            color="primary"
            variant="outlined"
            size="medium"
            startIcon={<ArrowBackIcon />}
            className={classes.formButton}
            onClick={handleBackCardsPage}
          >
            Voltar
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={false}
            size="medium"
            startIcon={<SaveIcon />}
            className={classes.formButton}
            onClick={handleNewUserCard}
            pending={requestingCreate}
          >
            Salvar
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
