import { useEffect } from 'react';
// material
import { Container, Card, Typography } from '@material-ui/core';
// components
import { ComponentsLib } from '@payface-frontends-libs/components';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DevicesQRCodeForm } from './DevicesQRCodeForm';
import { getDeviceById } from '../../redux/slices/devices';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

type ParamTypes = {
  id: string;
};

export function DevicesEditPage() {
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    void dispatch(getDeviceById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="CRM > Dispositivos">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Editar dados do dispositivo
        </Typography>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          <DevicesQRCodeForm type="edit" />
        </Card>
      </Container>
    </Page>
  );
}
