export function sample(array: Array<any> | string) {
  return array[Math.floor(Math.random() * array.length)] || undefined;
}

export function merge(target: any, ...sources: any) {
  for (const source of sources) {
    for (const key in source) {
      if (source[key] === null) continue;
      target[key] = replaceValues(target[key], source[key]);
    }
  }
  return target;
}

function isObject(obj: object) {
  return obj && obj.constructor === Object;
}

function replaceValues(currentValue: any, newValue: any): any {
  if (Array.isArray(currentValue) && Array.isArray(newValue)) {
    return newValue.map((value, index) => replaceValues(currentValue[index], value));
  }
  if (isObject(currentValue) && isObject(newValue)) {
    return merge(currentValue, newValue);
  }
  return newValue;
}
