import { AxiosResponse } from 'axios';
import { api } from './axios';

const identificationsEndpoints = {
  identifications: '/identifications',
  chart: '/identifications/report/chart',
  report: '/identifications/report',
};

export async function getCsvDataIdentifications({ period = 'ALL' }) {
  try {
    const params = { period };
    const response: void | AxiosResponse<any> = await api.get(identificationsEndpoints.identifications, { params });
    return response?.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
