import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { api } from '../../utils/axios';
import { store } from '../store';

const employeesEndpoints = {
  employees: '/places-employees',
};

type EmployeesStates = {
  isRequestingEmployees: boolean;
  errorMessageEmployees: string;
  deleted: boolean;
  employees: any;
  _order: string;
  _end: number;
  _start: number;
  _sort: string;
  reset: boolean;
  headerCount: number;
  updated: boolean;
  toggleOnEdit: boolean;
  errorMessageUpdatedPermissionsEmployee: string;
  resetPagination: boolean;
};

const initialState: EmployeesStates = {
  isRequestingEmployees: false,
  errorMessageEmployees: '',
  deleted: false,
  employees: [],
  _order: 'DESC',
  _end: 10,
  _start: 0,
  _sort: 'createdAt',
  reset: false,
  headerCount: 0,
  updated: false,
  toggleOnEdit: false,
  errorMessageUpdatedPermissionsEmployee: '',
  resetPagination: false,
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    requestingEmployees(state) {
      state.isRequestingEmployees = true;
    },
    setSort(state, action) {
      state._sort = action.payload;
    },
    setOrder(state, action) {
      state._order = action.payload;
    },
    getEmployeesSuccess(state, action) {
      state.isRequestingEmployees = false;
      state.employees = action.payload;
    },
    getEmployeesFail(state, action) {
      state.isRequestingEmployees = false;
      state.errorMessageEmployees = action.payload;
    },
    getPaginationStartAndEnd(state, action) {
      const { start, end } = action.payload;
      state._end = end;
      start._start = start;
    },
    getDeletedEmployees(state, action) {
      state.deleted = action.payload;
    },
    resetList(state, action) {
      state.reset = action.payload;
    },
    getEmployeesHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetPermissions(state, action) {
      state.updated = action.payload;
    },
    disablingToggleOnEdit(state, action) {
      state.toggleOnEdit = action.payload;
    },
    resetAllStates(state) {
      state.deleted = false;
      state.toggleOnEdit = false;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
  },
});

export default slice.reducer;

export function getEmployeesByPlace({
  _end,
  _order,
  _sort,
  _start,
  deleted,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  deleted: boolean;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingEmployees());
    dispatch(slice.actions.resetPagination(false));
    try {
      const queryStrings: any = {
        _end,
        _order,
        _sort,
        _start,
        deleted,
      };
      const response: void | AxiosResponse<any> = await api.get(employeesEndpoints.employees, {
        params: queryStrings,
      });

      const employees = response?.data;
      dispatch(slice.actions.getEmployeesSuccess(employees));
      dispatch(slice.actions.getEmployeesHeaders(Number(response?.headers['x-total-count'])));
    } catch (error) {
      dispatch(slice.actions.getEmployeesFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.setOrder(order));
  };
}

export function getDeletedEmployeesState(state: boolean) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getDeletedEmployees(state));
  };
}

export function resetUpdatedPermissions(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPermissions(state));
  };
}

export function disablingToggleOnEditState(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.disablingToggleOnEdit(state));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}

export function updateSortOption(field: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.setSort(field));
  };
}

export function resetEmployeesStates() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetAllStates());
  };
}
