import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  IconButton,
  Box,
  Tooltip,
  Skeleton,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getCreditsByPlace, updateDirectionOption, updateSortOption, getCreditById } from '../../redux/slices/credits';
import { RootState } from '../../redux/store';
import { CreditsDetails } from './CreditsDetails';
import { InputDialog } from '../library/InputDialog';
import Permissions from '../../hooks/Permissions';
import StatusChip from './StatusChip';

// ----------------------------------------------------------------------

const columns = [
  { id: 'expand', label: '', minWidth: 50, align: 'left' },
  { id: 'date', field: 'createdAt', sortable: true, label: 'Data', minWidth: 150, align: 'left' },
  { id: 'client', field: 'firstName', sortable: true, label: 'Cliente', minWidth: 220, align: 'left' },
  { id: 'value', field: 'amount', sortable: true, label: 'Valor', minWidth: 150, align: 'left' },
  { id: 'type', field: 'type', sortable: true, label: 'Tipo', minWidth: 150, align: 'center' },
  { id: 'cpf', field: 'cpf', sortable: true, label: 'CPF', minWidth: 150, align: 'center' },
  { id: 'manager', label: 'Ação', minWidth: 50, align: 'center' },
];

type CreditsDataListProps = {
  view: string;
};

export function CreditsDataList({ view }: CreditsDataListProps) {
  const dispatch = useDispatch();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);
  const [open, setOpen]: any = useState({
    status: false,
    reference: '',
  });
  const [openDisableDialog, setOpenDisableDialog]: any = useState({
    status: false,
    original: [],
    id: '',
  });
  const { credits, _order, headerCount, isRequestingCredits, _sort } = useSelector((state: RootState) => state.credits);
  const [sort, setSort] = useState(_sort);

  const [canCancel, setCanCanCel] = useState(false);

  const minArrayLength = 0;

  const handlePopover = (_event, index, id) => {
    if (open.status === true) {
      dispatch(getCreditById(''));
    } else {
      dispatch(getCreditById(id));
    }
    setOpen({ ...open, status: !open.status, reference: index });
  };

  const handleCancelCredit = (_event, _index, row) => {
    if (!openDisableDialog.status) {
      setOpenDisableDialog({ ...openDisableDialog, status: true, original: row, id: row.id });
    } else {
      setOpenDisableDialog({ ...openDisableDialog, status: false, id: '' });
    }
  };

  const renderManagementOptions = (index, row) => (
    <Box component="div" display="block">
      {row.amount === 'R$ 0,00' || !canCancel ? (
        <Box style={{ transform: 'translateX(16px)' }}>-</Box>
      ) : (
        <Tooltip title="Cancelar">
          <IconButton
            aria-label="disable"
            data-value={row.id}
            onClick={(event) => handleCancelCredit(event, index, row)}
          >
            <DeleteForeverIcon fontSize="small" color="error" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const renderExpand = (index: number, id: string) => (
    <Box component="div" display="flex" alignItems="center">
      <IconButton aria-label="expand" onClick={(event) => handlePopover(event, index, id)}>
        {open.status === true && index === open.reference ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );

  const personalizeValue = (value) => {
    if (value === 'R$ 0,00')
      return (
        <Typography sx={{ textDecoration: 'line-through' }} color="error" component="span" variant="body2">
          {value}
        </Typography>
      );
    return (
      <Typography color="primary" component="span" variant="body2">
        {value}
      </Typography>
    );
  };

  const renderSkeleton = (width: number, height: number) => (
    <Skeleton animation="wave" variant="rectangular" width={width} height={height} />
  );

  const creditsData = !isRequestingCredits
    ? credits.map((row, index) => {
        const created = moment(row.createdAt).format('DD/MM/YYYY HH:MM:SS');
        const user = `${row.firstName} ${row.lastName}`;
        return {
          expand: renderExpand(index, row.cardId),
          id: row.id,
          date: created,
          client: user,
          value: personalizeValue(row.amount),
          type: <StatusChip status={row.type} />,
          cpf: row.cpf,
          manager: renderManagementOptions(index, row),
          original: row,
        };
      })
    : [...Array(initialRows)].map(() => ({
        expand: renderSkeleton(50, 30),
        date: renderSkeleton(50, 30),
        client: renderSkeleton(220, 30),
        value: renderSkeleton(150, 30),
        type: renderSkeleton(150, 30),
        cpf: renderSkeleton(150, 30),
        manager: renderSkeleton(50, 30),
      }));

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = (field: string) => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
    dispatch(updateSortOption(field));
    setSort(field);
  };

  useEffect(() => {
    setCanCanCel(Permissions.hasPermission('CAN_CANCEL_TRANSACTION', Permissions.getPlace()));
  }, []);

  useEffect(() => {
    const user = view === 'credits' ? '' : JSON.parse(localStorage.getItem('user')!);

    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      dispatch(
        getCreditsByPlace({
          _end: end,
          _order,
          _sort: sort,
          _start: start,
          userId: '',
          user,
          period: 'ALL',
          type: 'ALL',
          cpf: '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, sort]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <InputDialog openDialog={openDisableDialog} setOpenDialog={setOpenDisableDialog} type="cancelCredit" />
      <TableContainer sx={{ maxHeight: 1000 }}>
        <Table stickyHeader aria-label="sticky table">
          {creditsData.length === minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.sortable ? (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                    <TableSortLabel
                      active={sort === column.field}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={() => handleDirection(column.field)}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {creditsData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return <TableCell key={column.id}>{value}</TableCell>;
                  })}
                </TableRow>
                <CreditsDetails position={index} open={open} />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
