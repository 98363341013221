import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import userReducer from './slices/user';
import identityVerficationsReducer from './slices/identityVerfications';
import transactionsReducer from './slices/transactions';
import clientsReducer from './slices/clients';
import employeesReducer from './slices/employees';
import cardsReducer from './slices/cards';
import creditsReducer from './slices/credits';
import apiKeysReducer from './slices/apiKeys';
import devicesReducer from './slices/devices';
import globalReducer from './slices/global';
import settingsReducer from './slices/settings';
import identificationsReducer from './slices/identifications';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings', ' clients', 'identifications'],
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  transactions: transactionsReducer,
  clients: clientsReducer,
  employees: employeesReducer,
  cards: cardsReducer,
  credits: creditsReducer,
  apiKeys: apiKeysReducer,
  devices: devicesReducer,
  global: globalReducer,
  user: userReducer,
  settings: settingsReducer,
  identityVerfications: identityVerficationsReducer,
  identifications: identificationsReducer,
});

export { rootPersistConfig, rootReducer };
