import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { UtilsLib } from '@payface-frontends-libs/utils';
import { store, persistor } from './redux/store';
import routes, { renderRoutes } from './routes';
import ThemeConfig from './utils';
import Settings from './components/settings';
import { googleAnalyticsConfig } from './config';

const { environmentConfig } = UtilsLib.Config;
const { switchFavicon } = UtilsLib.Generics;

const { LoadingScreen, ScrollToTop, GoogleAnalytics, NotistackProvider } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

switchFavicon(environmentConfig.stage);

const history = createBrowserHistory();

export default function App() {
  const props = {
    id: googleAnalyticsConfig,
  };
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            <NotistackProvider>
              <Router history={history}>
                <Settings />
                <ScrollToTop />
                <GoogleAnalytics {...props} />
                {renderRoutes(routes)}
              </Router>
            </NotistackProvider>
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
