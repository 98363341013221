import { Container, Card, Typography } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { EmployeesRegisterForm } from '../../components/_employees/EmployeesRegisterForm';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

export function CardsForm() {
  return (
    <Page title="Cadastrar Funcionário">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Cadastrar funcionário
        </Typography>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          <EmployeesRegisterForm />
        </Card>
      </Container>
    </Page>
  );
}
