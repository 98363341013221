import { api } from './axios';

const creditsEndpoints = {
  credit: '/post-paid-credits',
};

export async function deleteCredit({ id, password }: { id: string; password: string }) {
  try {
    const queryStrings = {
      pin: password,
    };
    const response = await api.delete(`${creditsEndpoints.credit}/${id}`, {
      params: queryStrings,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function addCredit({ amount, cardId, userId }: { amount: number; cardId: string; userId: string }) {
  try {
    const payload: object = {
      amount,
      cardId,
      userId,
    };

    const response = await api.post(creditsEndpoints.credit, payload);

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}
