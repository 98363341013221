import { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import MaskedInput from 'react-text-mask';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { useDispatch, useSelector } from 'react-redux';
import { getCsvDataIdentifications } from '../../utils/identificationsServices';
import { ExportButton } from '../library/ExportButton';
import { updateFilterOptions, resetPaginationData } from '../../redux/slices/identifications';
import { RootState } from '../../redux/store';

const periodReferences = {
  TODAY: 'Hoje',
  THIS_WEEK: 'Nesta semana (a partir de segunda-feira)',
  THIS_MONTH: 'Neste mês (a partir do dia 1°)',
  LAST_7_DAYS: 'Nos últimos 7 dias',
  LAST_30_DAYS: 'Nos últimos 30 dias',
  LAST_3_MONTHS: 'Nos últimos 3 meses',
  ALL: 'Todo o período',
};

const periodOptions = Object.values(periodReferences);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formButton: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function IdentificationsFilter() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [exportRequesting, setExportRequesting] = useState(false);
  const [cpfError, setCpfError] = useState('');
  const [cpf, setCpf] = useState('');

  const { reset } = useSelector((state: RootState) => state.global);

  const initialState = {
    period: 'ALL',
    cpf: '',
  };

  const [filter, setFilter] = useState(initialState);

  const validateCpf = (cpf: string) => {
    const isCPFValid = isValidCPF(cpf);
    if (isCPFValid === false) {
      setCpfError('CPF inválido');
    } else {
      setCpfError('');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'period') {
      const periodValues = Object.values(periodReferences);
      const periodKeys = Object.keys(periodReferences);
      const foundIndexOf = periodValues.findIndex((item) => item === value);
      setFilter((state) => ({ ...state, [name]: periodKeys[foundIndexOf] }));
    } else if (name === 'cpf') {
      validateCpf(value);
      setCpf(value);
      const cleanCpf = value.match(/\d/g).join('');
      setFilter((state) => ({ ...state, cpf: cleanCpf }));
    }
  };

  const renderList = (list) =>
    list.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(resetPaginationData(true));
    dispatch(updateFilterOptions(filter, true));
  };

  const handleClick = () => {
    setFilter(initialState);
    setCpf('');
    dispatch(resetPaginationData(true));
    dispatch(updateFilterOptions(initialState, true));
  };

  const handleExport = async () => {
    setExportRequesting(true);
    const result = await getCsvDataIdentifications({ period: initialState.period });
    if (result) {
      setExportRequesting(false);
      return result;
    }
  };

  useEffect(() => {
    if (reset === true) setFilter(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Box sx={{ mb: 1, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <form
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        onSubmit={(event) => handleSubmit(event)}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="payments-period">Período</InputLabel>
          <Select
            labelId="period-label"
            id="period"
            name="period"
            value={periodReferences[filter.period]}
            onChange={(event) => handleChange(event)}
            label="Período"
          >
            {renderList(periodOptions)}
          </Select>
        </FormControl>
        <FormControl>
          <MaskedInput
            mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholder="___.___.___-__"
            id="cpf"
            name="cpf"
            className={classes.formControl}
            onChange={(event) => handleChange(event)}
            render={(ref: any, props: any) => (
              <TextField
                {...props}
                style={{ minWidth: '282px' }}
                required={false}
                value={cpf}
                inputRef={ref}
                label="CPF"
                error={!!cpfError}
                helperText={cpfError}
              />
            )}
          />
        </FormControl>
        <LoadingButton
          type="submit"
          color="primary"
          variant="outlined"
          size="medium"
          onClick={() => handleSubmit}
          startIcon={<FilterListIcon />}
          className={classes.formButton}
        >
          Filtrar
        </LoadingButton>
        <LoadingButton
          type="button"
          onClick={handleClick}
          color="error"
          variant="outlined"
          size="medium"
          className={classes.formButton}
          startIcon={<ClearIcon />}
        >
          Limpar filtro
        </LoadingButton>
      </form>
      <Box sx={{ display: 'flex', marginRight: 2 }}>
        <ExportButton name="Identificacoes" handleExport={handleExport} isRequesting={exportRequesting} />
      </Box>
    </Box>
  );
}
