import { ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import { ComponentsLib } from '@payface-frontends-libs/components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';

import InputMask from 'react-input-mask';
import { useFormik } from 'formik';

import { PostPaidCardType } from '../../@types/client';
import { saveInviteUser } from '../../redux/slices/clients';
import { PATH_DASHBOARD } from '../../routes/paths';
import Permissions from '../../hooks/Permissions';

const { Page } = ComponentsLib.Generics;

const useStyles = makeStyles((theme) => ({
  formButton: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function ClientInvite() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [invite, sendingInvite] = useState(false);
  const [canAddPrePostCard, setCanAddPrePostCard] = useState(false);

  useEffect(() => {
    setCanAddPrePostCard(Permissions.hasFeature('HAS_POST_PAID'));
  }, []);

  const [selectedValueRadio, setSelectedValueRadio] = useState<string>('0');

  const { enqueueSnackbar } = useSnackbar();

  const handleChangevalueRadio = (event: ChangeEvent<HTMLInputElement>) => {
    const valueRadio = (event.target as HTMLInputElement).value;
    if (valueRadio) {
      setSelectedValueRadio(valueRadio);
      formik.values.postPaidCardType = valueRadio;
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: '',
      postPaidCardType: '',
    },
    onSubmit: (values) => {
      const { phone, postPaidCardType } = values;

      const typePaidCard = postPaidCardType;
      const convertEnumTypePaidCard = PostPaidCardType[typePaidCard];

      dispatch(
        saveInviteUser({
          phone,
          postPaidCardType: convertEnumTypePaidCard,
        })
      );
    },
  });

  const handleBackClientPage = () => {
    history.push(PATH_DASHBOARD.clients.root);
  };

  const handleSendInvite = async () => {
    sendingInvite(true);
    try {
      await formik.handleSubmit();
      sendingInvite(false);
      enqueueSnackbar('Convite enviado com sucesso', { variant: 'success' });
      history.push(PATH_DASHBOARD.clients.root);
    } catch (error) {
      sendingInvite(false);
      enqueueSnackbar('Não foi possível enviar o convite', { variant: 'error' });
    }
  };

  return (
    <Page title="Convidar pessoa">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Convidar pessoa
        </Typography>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          <form onSubmit={formik.handleSubmit}>
            <InputMask mask="(99)99999-9999" maskChar=" " value={formik.values.phone} onChange={formik.handleChange}>
              {() => <TextField name="phone" label="Telefone" />}
            </InputMask>
            {canAddPrePostCard && (
              <Box>
                <FormControl component="fieldset">
                  <Typography sx={{ mt: 3, mb: 2 }} variant="subtitle1">
                    Deseja criar um cartão para a pessoa convidada?
                  </Typography>
                  <RadioGroup
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={selectedValueRadio}
                    onChange={handleChangevalueRadio}
                  >
                    <FormControlLabel value="0" control={<Radio />} label="Não criar um cartão" />
                    <FormControlLabel value="post_paid" control={<Radio />} label="Pós-pago" />
                    <FormControlLabel value="pre_paid" control={<Radio />} label="Pré-pago" />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mt: 4,
              }}
            >
              <Button
                type="button"
                color="primary"
                variant="outlined"
                size="medium"
                startIcon={<ArrowBackIcon />}
                className={classes.formButton}
                onClick={handleBackClientPage}
              >
                Voltar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                disabled={!formik.dirty}
                size="medium"
                startIcon={<SaveIcon />}
                className={classes.formButton}
                onClick={handleSendInvite}
                pending={invite}
              >
                Salvar
              </LoadingButton>
            </Box>
          </form>
        </Card>
      </Container>
    </Page>
  );
}
