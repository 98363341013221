import { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterOptions, resetPaginationData, updatePeriod, updateType } from '../../redux/slices/credits';
import { RootState } from '../../redux/store';
import { FilterButtons } from '../library/FilterButtons';
import { periodFilter, typeFilter } from '../../@types/credits';
import { CreditStyleClasses } from './CreditStyleClasses';

const periodReferences = {
  [periodFilter.TODAY]: 'Hoje',
  [periodFilter.THIS_WEEK]: 'Nesta semana (a partir de segunda-feira)',
  [periodFilter.THIS_MONTH]: 'Neste mês (a partir do dia 1°)',
  [periodFilter.LAST_7_DAYS]: 'Nos últimos 7 dias',
  [periodFilter.LAST_30_DAYS]: 'Nos últimos 30 dias',
  [periodFilter.LAST_3_MONTHS]: 'Nos últimos 3 meses',
  [periodFilter.ALL]: 'Todo o período',
};

const typeReferences = {
  [typeFilter.ALL]: 'Todos',
  [typeFilter.CREDIT]: 'Créditos',
  [typeFilter.CANCELATION]: 'Cancelamentos',
};

const formNames = {
  PERIOD: 'period',
  TYPE: 'type',
  CPF: 'cpf',
};

const periodOptions = Object.values(periodReferences);

const typeOptions = Object.values(typeReferences);

export function CreditsFormFilter() {
  const dispatch = useDispatch();
  const classes = CreditStyleClasses();

  const { period, type, isRequestingCredits } = useSelector((state: RootState) => state.credits);
  const { reset } = useSelector((state: RootState) => state.global);

  const initialState = {
    period,
    type,
    cpf: '',
  };

  const [filter, setFilter] = useState(initialState);
  const [filterRequesting, setFilterRequesting] = useState(false);
  const [clearRequesting, setClearRequesting] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [cpf, setCpf] = useState('');

  const handleResetFilter = () => {
    setFilter(initialState);
    dispatch(resetPaginationData(true));
    dispatch(updateFilterOptions(initialState));
    dispatch(updatePeriod(periodFilter.ALL));
    dispatch(updateType(typeFilter.ALL));
    setCpf('');
  };

  const validateCpf = (cpf: string) => {
    setCpfError(!isValidCPF(cpf));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === formNames.PERIOD) {
      const periodValues = Object.values(periodReferences);
      const periodKeys = Object.keys(periodReferences);
      const foundIndexOf = periodValues.findIndex((item) => item === value);
      setFilter((state) => ({ ...state, [name]: periodKeys[foundIndexOf] }));
      dispatch(updatePeriod(periodKeys[foundIndexOf]));
    } else if (name === formNames.TYPE) {
      const typeValues = Object.values(typeReferences);
      const typeKeys = Object.keys(typeReferences);
      const foundIndexOf = typeValues.findIndex((item) => item === value);
      setFilter((state) => ({ ...state, [name]: typeKeys[foundIndexOf] }));
      dispatch(updateType(typeKeys[foundIndexOf]));
    } else if (name === formNames.CPF) {
      validateCpf(value);
      setCpf(value);
      const cleanCpf = value.match(/\d/g).join('');
      setFilter((state) => ({ ...state, [name]: cleanCpf }));
    }
  };

  const renderList = (list) =>
    list.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(resetPaginationData(true));
    dispatch(updateFilterOptions(filter));
  };

  useEffect(() => {
    if (reset) {
      setFilter(initialState);
      setFilterRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (!isRequestingCredits) {
      setFilterRequesting(false);
      setClearRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingCredits]);

  useEffect(() => {
    setFilter((state) => ({ ...state, period }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    setFilter((state) => ({ ...state, type }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Box className={classes.boxFormFilter}>
      <form className={classes.formFilter} id="creditsForm" onSubmit={(event) => handleSubmit(event)}>
        <Box className={classes.boxFields}>
          <FormControl>
            <MaskedInput
              mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              placeholder="___.___.___-__"
              id={formNames.CPF}
              name={formNames.CPF}
              className={classes.formControl}
              onChange={(event) => handleChange(event)}
              render={(ref: any, props: any) => (
                <TextField
                  {...props}
                  style={{ minWidth: '282px' }}
                  required={false}
                  value={cpf}
                  inputRef={ref}
                  label="CPF"
                  error={cpfError}
                />
              )}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="credits-period">Período</InputLabel>
            <Select
              labelId="period-label"
              id={formNames.PERIOD}
              name={formNames.PERIOD}
              value={periodReferences[filter.period]}
              onChange={(event) => handleChange(event)}
              label="Período"
            >
              {renderList(periodOptions)}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="credits-type">Tipo</InputLabel>
            <Select
              labelId="type-label"
              id={formNames.TYPE}
              name={formNames.TYPE}
              value={typeReferences[filter.type]}
              onChange={(event) => handleChange(event)}
              label="Tipo"
            >
              {renderList(typeOptions)}
            </Select>
          </FormControl>
          <FilterButtons
            initialState={initialState}
            filterRequesting={filterRequesting}
            clearRequesting={clearRequesting}
            setFilter={setFilter}
            setClearRequesting={handleResetFilter}
            formId="creditsForm"
            updateFilter={updateFilterOptions}
            resetPagination={resetPaginationData}
          />
        </Box>
      </form>
    </Box>
  );
}
