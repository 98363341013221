export enum periodFilter {
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  ALL = 'ALL',
}

export enum typeFilter {
  ALL = 'ALL',
  CREDIT = 'CREDIT',
  CANCELATION = 'CANCELATION',
}
