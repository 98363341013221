import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Card,
  Box,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
  FormControl,
} from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { Icon } from '@iconify/react';
import baselineAdd from '@iconify/icons-ic/baseline-add';
import { CardsDataList } from '../../components/_cards/CardsDataList';
import { resetDataList } from '../../redux/slices/global';
import { resetCreditsStates } from '../../redux/slices/credits';
import { RootState } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import { CardInfo } from '../../components/_cards/CardInfo';
import {
  getCardsByPlace,
  getReport,
  getLatePaymentsState,
  getUsersByPlace,
  resetCardsStates,
} from '../../redux/slices/cards';

import { CardsChartCard } from '../../components/_cards/CardsChartCard';
import { CardsFormFilter } from '../../components/_cards/CardsFormFilter';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

export function Cards() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [latePayment, setLatePayment] = useState(false);
  const { _end, _order, _start, late, name, resetPagination } = useSelector((state: RootState) => state.cards);

  const { reset } = useSelector((state: RootState) => state.global);

  const handleLate = () => {
    setLatePayment(!latePayment);
    dispatch(getLatePaymentsState(!latePayment));
  };

  const handleClick = () => {
    history.push(PATH_DASHBOARD.cards.create);
  };

  useEffect(() => {
    const _sort = 'createdAt';
    const end = 1000;
    const order = 'DESC';
    const start = 0;
    if (reset === true) {
      void dispatch(resetDataList(false));
    }
    void dispatch(getCardsByPlace({ _end, _order, _sort, _start, q: name, late: latePayment, user: '' }));
    void dispatch(getReport());
    void dispatch(getUsersByPlace({ _end: end, _order: order, _sort, _start: start }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_order, reset, late, name, resetPagination]);

  useEffect(() => {
    void dispatch(resetCardsStates());
    void dispatch(resetCreditsStates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="CRM > Cartões">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Cartões
        </Typography>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={3}>
            <CardInfo title="Pós-pago" />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardInfo title="Pagamentos em atraso" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardsChartCard />
          </Grid>
        </Grid>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              p: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
              <CardsFormFilter />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
              <FormControl>
                <FormControlLabel
                  control={<Switch name="late" checked={latePayment} onChange={handleLate} />}
                  label="Mostrar somente pagamentos atrasados"
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button
                variant="contained"
                type="button"
                onClick={handleClick}
                startIcon={<Icon icon={baselineAdd} />}
                size="medium"
              >
                Adicionar
              </Button>
            </Box>
          </Box>
          <Box>
            <CardsDataList />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
