import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getCsvDataTransactions } from '../../utils/paymentsServices';
import {
  updateFilterOptions,
  resetPaginationData,
  updatePeriod,
  updateStatus,
  updateFlag,
  updateProduct,
  updateColumn,
} from '../../redux/slices/transactions';
import { RootState } from '../../redux/store';
import { ExportButton } from '../library/ExportButton';
import { FilterButtons } from '../library/FilterButtons';
import { Messages } from '../../@types/messages';

// ----------------------------------------------------------------------

const periodReferences = {
  TODAY: 'Hoje',
  THIS_WEEK: 'Nesta semana (a partir de segunda-feira)',
  THIS_MONTH: 'Neste mês (a partir do dia 1°)',
  LAST_7_DAYS: 'Nos últimos 7 dias',
  LAST_30_DAYS: 'Nos últimos 30 dias',
  LAST_3_MONTHS: 'Nos últimos 3 meses',
  ALL: 'Todo o período',
};

const statusReferences = {
  ALL: 'Todos',
  FAIL: 'Não efetivado',
  SUCCESS: 'Efetivado',
  CANCELED: 'Cancelado',
};

const optionsValueList = {
  ALL: 'Todos',
  ALL_ITEMS: 'ALL',
};

const periodOptions = Object.values(periodReferences);

const statusOptions = ['Todos', 'Efetivado', 'Cancelado', 'Não efetivado'];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formButton: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formWarning: {
    marginLeft: theme.spacing(3),
    marginTop: '-0.4em',
    fontSize: '0.85em',
    color: '#FF4842',
    minWidth: 120,
  },
}));

const columnsHeadersWithProductItem = [
  { id: 'date', label: 'Data', width: 170, align: 'left' },
  { id: 'client', label: 'Cliente', width: 250, align: 'right' },
  {
    id: 'value',
    label: 'Valor',
    width: 100,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'installments',
    label: 'Parcelas',
    width: 50,
    align: 'right',
  },
  {
    id: 'flag',
    label: 'Bandeira',
    width: 120,
    align: 'right',
  },
  {
    id: 'product',
    label: 'Produto',
    width: 70,
    align: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    width: 50,
    align: 'right',
  },
];

const columnsHeadersOriginal = [
  { id: 'date', label: 'Data', width: 170, align: 'left' },
  { id: 'client', label: 'Cliente', width: 250, align: 'right' },
  {
    id: 'value',
    label: 'Valor',
    width: 100,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'installments',
    label: 'Parcelas',
    width: 50,
    align: 'right',
  },
  {
    id: 'flag',
    label: 'Bandeira',
    width: 120,
    align: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    width: 50,
    align: 'right',
  },
];

export function TransactionsFormFilter() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    switchOptions,
    _order,
    period,
    status,
    flag,
    product,
    isRequestingTransactions,
    authorizersPlace,
    listProducts,
  } = useSelector((state: RootState) => state.transactions);

  const flagReferences = {
    ALL: 'Todas',
    ...authorizersPlace,
  };
  const getSwitches = () => Object.values(flagReferences);
  const { reset } = useSelector((state: RootState) => state.global);

  const initialState = {
    period,
    status,
    flag,
    product,
    client: '',
  };

  const [filter, setFilter] = useState(initialState);
  const [privateLabelProduct, setPrivateLabelProduct] = useState<any>();
  const [privateLabelItem, setPrivateLabelItem] = useState<any>('Todos');
  const [filterRequesting, setFilterRequesting] = useState(false);
  const [clearRequesting, setClearRequesting] = useState(false);
  const [exportRequesting, setExportRequesting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'period') {
      const periodValues = Object.values(periodReferences);
      const periodKeys = Object.keys(periodReferences);
      const foundIndexOf = periodValues.findIndex((item) => item === value);
      setFilter({ ...filter, [name]: periodKeys[foundIndexOf] });
      dispatch(updatePeriod(periodKeys[foundIndexOf]));
    } else if (name === 'status') {
      const statusValues = Object.values(statusReferences);
      const statusKeys = Object.keys(statusReferences);
      const foundIndexOf = statusValues.findIndex((item) => item === value);
      setFilter({ ...filter, [name]: statusKeys[foundIndexOf] });
      dispatch(updateStatus(statusKeys[foundIndexOf]));
    } else if (name === 'flag') {
      dispatch(updateColumn(columnsHeadersOriginal));
      const flagValues = Object.values(flagReferences);
      const flagKeys = Object.keys(flagReferences);
      const foundIndexOf = flagValues.findIndex((item) => item === value);
      const authorizerItem = switchOptions.find((item) => item.name === value);
      const isPrivateLabel = authorizerItem?.isPrivateLabel;
      setPrivateLabelProduct(isPrivateLabel ? listProducts : null);
      dispatch(updateProduct(optionsValueList.ALL_ITEMS));
      if (isPrivateLabel) {
        setPrivateLabelItem(optionsValueList.ALL_ITEMS);
        dispatch(updateColumn(columnsHeadersWithProductItem));
      }
      setFilter({ ...filter, [name]: flagKeys[foundIndexOf], product: optionsValueList.ALL_ITEMS });
      dispatch(updateFlag(flagKeys[foundIndexOf]));
    } else if (name === 'product') {
      const valueFormated = value === optionsValueList.ALL ? optionsValueList.ALL_ITEMS : value;
      setPrivateLabelItem(valueFormated);
      setFilter({ ...filter, [valueFormated]: value });
      dispatch(updateProduct(valueFormated));
    }
  };

  useEffect(() => {
    setPrivateLabelProduct(listProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProducts]);

  const renderList = (list) =>
    list.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(resetPaginationData(true));
    // dispatch(updateFilterOptions(filter));
    setFilterRequesting(true);
  };

  const handleExport = async () => {
    setExportRequesting(true);
    const end = 1000;
    const start = 0;
    const sort = 'createdAt';
    try {
      const result = await getCsvDataTransactions({
        _end: end,
        _order,
        _sort: sort,
        _start: start,
        period,
        authorizer: flag,
        status,
      });
      setExportRequesting(false);
      return result;
    } catch (error: any) {
      enqueueSnackbar(Messages[error.message], {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      setExportRequesting(false);
      return undefined;
    }
  };

  useEffect(() => {
    if (reset === true) {
      setFilter(initialState);
      setFilterRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (isRequestingTransactions === false) {
      setFilterRequesting(false);
      setClearRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingTransactions]);

  useEffect(() => {
    setFilter({ ...filter, period });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    setFilter({ ...filter, status });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setFilter({ ...filter, flag });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  useEffect(() => {
    setFilter({ ...filter, product });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <Box
      sx={{ mb: 1, mt: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <form
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
        id="paymentsForm"
        onSubmit={(event) => handleSubmit(event)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="payments-period">Período</InputLabel>
            <Select
              labelId="period-label"
              id="period"
              name="period"
              value={periodReferences[filter.period]}
              onChange={(event) => handleChange(event)}
              label="Período"
            >
              {renderList(periodOptions)}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="payments-status">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              name="status"
              value={statusReferences[filter.status]}
              onChange={(event) => handleChange(event)}
              label="Status"
            >
              {renderList(statusOptions)}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="payments-flag">Bandeira</InputLabel>
            <Select
              labelId="flag-label"
              id="flag"
              name="flag"
              value={flagReferences[filter.flag]}
              onChange={(event) => handleChange(event)}
              label="Bandeira"
            >
              {renderList([...getSwitches()])}
            </Select>
          </FormControl>
          {privateLabelProduct && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="payments-products">Produtos</InputLabel>
              <Select
                labelId="products-label"
                id="product"
                name="product"
                value={privateLabelItem === 'ALL' ? 'Todos' : privateLabelItem}
                onChange={(event) => handleChange(event)}
                label="Produto"
              >
                {renderList(['Todos', ...privateLabelProduct])}
              </Select>
            </FormControl>
          )}
          <FilterButtons
            initialState={initialState}
            filterRequesting={filterRequesting}
            clearRequesting={clearRequesting}
            setFilter={setFilter}
            setClearRequesting={setClearRequesting}
            formId="paymentsForm"
            updateFilter={updateFilterOptions}
            resetPagination={resetPaginationData}
          />
        </Box>
        <Box sx={{ display: 'flex', marginRight: 2 }}>
          <ExportButton
            handleExport={handleExport}
            isRequesting={exportRequesting}
            name={Messages.TRANSACTION_FILE_NAME}
          />
        </Box>
      </form>
      <div className={classes.formWarning}>
        Atenção! As informações de data são baseadas no Fuso horário de Brasília, DF (GMT-3).
      </div>
    </Box>
  );
}
