import { Box, TableHead, TableCell, TableBody, TableRow, Table, Collapse } from '@material-ui/core';
import moment from 'moment';

// ----------------------------------------------------------------------

type Status = {
  data: any;
  position: number;
  open: {
    status: boolean;
    reference: number;
  };
};

export function EmployeesDetails({ data, position, open: { status, reference } }: Status) {
  return (
    <>
      {position === reference && (
        <TableRow key={position}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={status} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>CPF</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Data de desativação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">{data.id}</TableCell>
                      <TableCell align="left">{data.cpf}</TableCell>
                      <TableCell align="left">{data.status === true ? 'Inativo' : 'Ativo'}</TableCell>
                      <TableCell align="left">
                        {data.status === true ? moment(data.deletedAt).format('DD/MM/YYYY') : '----------'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
