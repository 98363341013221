import { useEffect, useState } from 'react';
// material
import { Card, CardHeader, Skeleton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ChartDonut } from './ChartDonut';
import { RootState } from '../../redux/store';

// ----------------------------------------------------------------------

export function CardsChartCard() {
  const { report, isRequestingReport } = useSelector((state: RootState) => state.cards);
  const [cardsData, setCardsData]: any = useState([]);
  const [labels, setLabels]: any[] = useState([]);

  useEffect(() => {
    const noValueFound = 0;

    if (report.id) {
      const postPaid = report.cards.POST_PAID || noValueFound;
      const prePaid = report.cards.PRE_PAID || noValueFound;
      const Blocked = report.blocked || noValueFound;
      const Removed = report.removed || noValueFound;
      const newReport = [postPaid, prePaid, Blocked, Removed];
      setCardsData(newReport);
      setLabels(['Pós-pago', 'Pré-pago', 'Bloqueados', 'Removidos']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Card sx={{ flexGrow: 1, height: 300, display: 'flex', flexDirection: 'column' }}>
      <CardHeader title="Cartões" />
      {isRequestingReport ? (
        <Skeleton
          sx={{ display: 'flex', alignSelf: 'center' }}
          animation="wave"
          variant="circular"
          width={150}
          height={150}
        />
      ) : (
        <ChartDonut series={cardsData} labels={labels} />
      )}
    </Card>
  );
}
