import NProgress from 'nprogress';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { Suspense, Fragment, useEffect, useMemo, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { ViewsLib } from '@payface-frontends-libs/views';
import GuestGuard from '../components/guards/GuestGuard';
import { url } from '../config';
import { PATH_PAGE, PATH_AUTH, PATH_DASHBOARD } from './paths';
import DashboardRoutes from './dashboard.routes';
import HomeRoutes from './home.routes';
import Permissions from '../hooks/Permissions';

const { Page500, Page404 } = ViewsLib.Common;
const { Login } = ViewsLib.Authentication;
const { LoadingScreen } = ComponentsLib.Generics;

const place = Permissions.getPlace();

const firstView = (): string => {
  const permissions = {
    CAN_MANAGE_PAYMENT: PATH_DASHBOARD.general.app,
    CAN_MANAGE_USER: PATH_DASHBOARD.employees.root,
    CAN_MANAGE_POST_PRE: PATH_DASHBOARD.cards.root,
    CAN_MANAGE_DEVICE: PATH_DASHBOARD.devices.root,
    CAN_MANAGE_CLIENT: PATH_DASHBOARD.clients.root,
    CAN_MANAGE_KEY: PATH_DASHBOARD.apikeys.root,
  };
  const keys = Object.keys(permissions);
  const permission = keys.find((key) => Permissions.hasPermission(key, place));
  return permission ? permissions[permission] : permissions.CAN_MANAGE_PAYMENT;
};

const loginProps = {
  loginPath: '/auth/verify',
  loginEndpoint: `${url}/login/start`,
  pageName: 'CRM',
  verifyPath: firstView(),
  passwordEndpoint: `${url}/login/confirm-credential`,
  otpEndpoint: `${url}/login/confirm-phone`,
};

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
      },
    },
  },
}));

function RouteProgress(props: RouteProps) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes: RouteItem[] = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, idx) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={`routes-${idx}`}
              path={route.path}
              exact={route.exact}
              render={(props: any) => (
                <Guard>
                  <Layout>
                    {route.routes && route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

type RouteItem = {
  exact?: boolean;
  guard?: ({ children }: { children: ReactNode }) => JSX.Element;
  path?: string | string[];
  component?: any;
  layout?: ({ children }: { children: ReactNode }) => JSX.Element;
  routes?: {
    component: any;
    path?: string | string[];
    exact?: boolean;
  }[];
};

const routes: RouteItem[] = [
  {
    exact: true,
    guard: GuestGuard,
    path: [PATH_AUTH.login],
    component: () => <Login {...loginProps} />,
  },
  {
    exact: true,
    path: PATH_PAGE.page404,
    component: () => <Page404 />,
  },
  {
    exact: true,
    path: PATH_PAGE.page500,
    component: () => <Page500 />,
  },
  {
    exact: true,
    path: PATH_AUTH.root,
    component: () => <Redirect to={PATH_AUTH.login} />,
  },
  DashboardRoutes,
  HomeRoutes,
];

export default routes;
