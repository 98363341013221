import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Box, Skeleton } from '@material-ui/core';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { CardsStatus } from '../_cards/CardsStatus';
import { CardsCreditType } from '../_cards/CardsCreditType';
import { getUserById, getCardById, resetPaginationData } from '../../redux/slices/cards';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[600],
  },
  picture: {
    border: '5px solid grey',
    borderRadius: '50%',
  },
}));

type ParamTypes = {
  id: string;
};

export function ClientsPanelCards() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();
  const { card, user, resetPagination, isRequestingUser, isRequestingCard } = useSelector(
    (state: RootState) => state.cards
  );
  const [cardInfo, setCardInfo]: any = useState({});

  useEffect(() => {
    if (!isRequestingUser && !isRequestingCard && user.id === id) {
      const formattedDate = moment(card.createdAt).format('DD/MM/YYYY HH:mm:ss');
      const formattedClient = `${user.firstName} ${user ? user.lastName : ''}`;

      setCardInfo({
        date: formattedDate,
        client: formattedClient,
        cpf: card.userCpf,
        mobile: card.userPhone,
        balance: card.balanceFormatted,
        type: <CardsCreditType paymentType={card.type} />,
        status: <CardsStatus deleted={card.deleted} blocked={card.blocked} late={card.late} />,
        id: card.id,
        user: card.userId,
      });
    } else {
      setCardInfo({
        date: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        client: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        cpf: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        mobile: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        balance: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        type: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        status: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, card]);

  useEffect(() => {
    if (resetPagination === true) {
      void dispatch(getUserById(JSON.parse(localStorage.getItem('user')!)));
      void dispatch(getCardById(id));
      void dispatch(resetPaginationData(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPagination]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={0} sx={{ mt: 1, mb: 3, mx: 0 }}>
          <Grid container item xs={12} md={8} sx={{ pl: 4 }}>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Cliente
              </Typography>
              <Typography variant="body1">{cardInfo.client}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                CPF
              </Typography>
              <Typography variant="body1">{cardInfo.cpf}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Celular
              </Typography>
              <Typography variant="body1">{cardInfo.mobile}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Tipo
              </Typography>
              <Box>{cardInfo.type}</Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Data de emissão
              </Typography>
              <Typography variant="body1">{cardInfo.date}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Saldo
              </Typography>
              <Typography variant="body1">{cardInfo.balance}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Status
              </Typography>
              <Box>{cardInfo.status}</Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
