import { Chip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';

export default function StatusChip({ status }: { status: string }) {
  const statusReferences = {
    DEFAULT: (
      <Chip
        variant="outlined"
        icon={<ErrorIcon />}
        sx={{ borderColor: 'grey.main', color: 'grey.main' }}
        label="Desconhecido"
      />
    ),
    CREDIT: <Chip variant="outlined" icon={<AddIcon />} color="primary" label="Crédito" />,
    CANCELATION: (
      <Chip
        variant="outlined"
        icon={<CancelIcon />}
        sx={{ borderColor: 'error.main', color: 'error.main' }}
        label="Cancelamento"
      />
    ),
  };

  return statusReferences[status] || statusReferences.DEFAULT;
}
