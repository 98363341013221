import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Card, Box, Button, Typography, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { Icon } from '@iconify/react';
import baselineAdd from '@iconify/icons-ic/baseline-add';
import { useSnackbar } from 'notistack';
import { getEmployeesByPlace, getDeletedEmployeesState, resetUpdatedPermissions } from '../../redux/slices/employees';
import { EmployeesDataList } from '../../components/_employees';
import { resetDataList } from '../../redux/slices/global';
import { getCsvDataEmployees } from '../../utils/employeeServices';
import { RootState } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import { ExportButton } from '../../components/library/ExportButton';
import { Messages } from '../../@types/messages';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

export function Employees() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [exportRequesting, setExportRequesting] = useState(false);
  const { _end, _order, _sort, _start, deleted, updated, toggleOnEdit } = useSelector(
    (state: RootState) => state.employees
  );

  const { reset } = useSelector((state: RootState) => state.global);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (reset === true) {
      void dispatch(resetDataList(false));
    }
    if (updated === true) {
      void dispatch(resetUpdatedPermissions(false));
    }
    void dispatch(getEmployeesByPlace({ _end, _order, _sort, _start, deleted }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_order, deleted, updated, reset]);

  const handleChange = () => {
    dispatch(getDeletedEmployeesState(!checked));
    setChecked(!checked);
  };

  const handleClick = () => {
    history.push(PATH_DASHBOARD.employees.form);
  };

  const handleExport = async () => {
    setExportRequesting(true);
    const end = 1000;
    const start = 0;
    const sort = 'createdAt';
    try {
      const result = await getCsvDataEmployees({ _end: end, _order, _sort: sort, _start: start, deleted });
      setExportRequesting(false);
      return result;
    } catch (error: any) {
      enqueueSnackbar(Messages[error.message], {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      setExportRequesting(false);
      return undefined;
    }
  };

  return (
    <Page title="CRM > Funcionários">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Funcionários
        </Typography>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              p: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={checked} onChange={handleChange} disabled={toggleOnEdit !== false} />}
                label="Mostrar funcionários desativados"
              />
            </FormGroup>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button
                variant="contained"
                type="button"
                onClick={handleClick}
                startIcon={<Icon icon={baselineAdd} />}
                sx={{ mr: 2 }}
                size="medium"
              >
                Adicionar
              </Button>
              <Box sx={{ display: 'flex' }}>
                <ExportButton
                  handleExport={handleExport}
                  isRequesting={exportRequesting}
                  name={Messages.EMPLOYER_FILE_NAME}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <EmployeesDataList />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
