import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { periodFilter, typeFilter } from '../../@types/credits';
import { api } from '../../utils/axios';
import { store } from '../store';

const creditsEndpoints = {
  credits: '/post-paid-credits',
  cards: 'post-paid-cards',
};

type PeriodOptions = keyof typeof periodFilter;
type TypeOptions = keyof typeof typeFilter;

type CreditsStates = {
  isRequestingCredits: boolean;
  errorMessageCredits: string;
  isRequestingCredit: boolean;
  errorMessageCredit: string;
  credits: any;
  credit: any;
  period: PeriodOptions;
  type: TypeOptions;
  cpf: string;
  _order: string;
  _end: number;
  _start: number;
  _sort: string;
  headerCount: number;
  updated: boolean;
  resetPagination: boolean;
};

const initialState: CreditsStates = {
  isRequestingCredits: false,
  errorMessageCredits: '',
  isRequestingCredit: false,
  errorMessageCredit: '',
  credits: [],
  credit: {},
  period: periodFilter.ALL,
  type: typeFilter.ALL,
  cpf: '',
  _order: 'DESC',
  _end: 10,
  _start: 0,
  _sort: 'createdAt',
  headerCount: 0,
  updated: false,
  resetPagination: false,
};

const slice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    requestingCredits(state) {
      state.isRequestingCredits = true;
    },
    getCreditsSuccess(state, action) {
      state.isRequestingCredits = false;
      state.credits = action.payload;
    },
    getCreditsFail(state, action) {
      state.isRequestingCredits = false;
      state.errorMessageCredits = action.payload;
    },
    requestingCredit(state) {
      state.isRequestingCredit = true;
    },
    getCreditSuccess(state, action) {
      state.isRequestingCredit = false;
      state.credit = action.payload;
    },
    getFilters(state, action) {
      const { period, type, cpf } = action.payload;
      state.period = period;
      state.type = type;
      state.cpf = cpf;
    },
    getPeriod(state, action) {
      state.period = action.payload;
    },
    getType(state, action) {
      state.type = action.payload;
    },
    getCreditFail(state, action) {
      state.isRequestingCredit = false;
      state.errorMessageCredit = action.payload;
    },
    setOrder(state, action) {
      state._order = action.payload;
    },
    setSort(state, action) {
      state._sort = action.payload;
    },
    getPaginationStartAndEnd(state, action) {
      const { start, end } = action.payload;
      state._end = end;
      start._start = start;
    },
    getCreditsHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetPermissions(state, action) {
      state.updated = action.payload;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
    resetAllStates(state) {
      state.credits = [];
      state.credit = {};
    },
  },
});

export default slice.reducer;

export function getCreditsByPlace({
  _end,
  _order,
  _sort,
  _start,
  userId,
  user,
  period,
  type,
  cpf,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  userId: string;
  user: string;
  period: PeriodOptions;
  type: TypeOptions;
  cpf: string;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingCredits());
    try {
      const queryStrings: any = {
        _end,
        _order,
        _sort,
        _start,
        period,
        type: type === typeFilter.ALL ? undefined : type,
        cpf: cpf.length ? cpf : undefined,
      };

      userId !== '' && (queryStrings.userId = userId);
      user !== '' && (queryStrings.user = user);

      const response: void | AxiosResponse<any> = await api.get(creditsEndpoints.credits, {
        params: queryStrings,
      });

      const credits = response?.data;

      dispatch(slice.actions.getCreditsSuccess(credits));
      dispatch(slice.actions.getCreditsHeaders(Number(response?.headers['x-total-count'])));
    } catch (error: any) {
      dispatch(slice.actions.getCreditsFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function getCreditById(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingCredit());

    try {
      if (id === '') {
        dispatch(slice.actions.getCreditSuccess({}));
      } else {
        const queryStrings = {
          id,
        };
        const response: void | AxiosResponse<any> = await api.get(creditsEndpoints.cards, {
          params: queryStrings,
        });
        const [credit] = response?.data;
        dispatch(slice.actions.getCreditSuccess(credit));
      }
    } catch (error) {
      dispatch(slice.actions.getCreditFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.setOrder(order));
  };
}

export function updateFilterOptions({ period, type, cpf }: { period: PeriodOptions; type: TypeOptions; cpf: string }) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getFilters({ period, type, cpf }));
  };
}

export function updatePeriod(period: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getPeriod(period));
  };
}

export function updateType(type: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getType(type));
  };
}

export function updateSortOption(field: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.setSort(field));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}

export function resetCreditsStates() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetAllStates());
  };
}
