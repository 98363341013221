import { AxiosResponse } from 'axios';
import { api } from './axios';

const devicesEndpoints = {
  devices: '/places-devices',
};

export async function getCsvDataDevices({
  _end,
  _order,
  _sort,
  _start,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
}) {
  try {
    const queryStrings = {
      _end,
      _order,
      _sort,
      _start,
    };

    const response: void | AxiosResponse<any> = await api.get(devicesEndpoints.devices, {
      params: queryStrings,
    });

    const devices = response?.data;

    return devices;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function addDevice({ deviceUUID, name }: { deviceUUID: string; name: string }) {
  try {
    const payload: object = {
      deviceUUID,
      name,
    };

    const response = await api.post(devicesEndpoints.devices, payload);

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function updateDeviceById({
  name,
  deviceUUID,
  original,
}: {
  name: string;
  deviceUUID: string;
  original: any;
}) {
  try {
    const payload: object = { ...original, name, deviceUUID };

    const response: void | AxiosResponse<any> = await api.put(`${devicesEndpoints.devices}/${original.id}`, payload);
    return response?.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function deleteDeviceById(id: string) {
  try {
    const response: void | AxiosResponse<any> = await api.delete(`${devicesEndpoints.devices}/${id}`);
    return response?.data;
  } catch (error) {
    throw new Error(error.message);
  }
}
