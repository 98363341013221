import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

type CurrencyInputProps = {
  handleCredit(e): void;
  disableText: boolean;
};

export default function CurrencyInput({ handleCredit, disableText }: CurrencyInputProps) {
  return (
    <NumberFormat
      customInput={TextField}
      label={disableText ? 'R$ 0,00' : 'Valor do crédito'}
      disabled={disableText}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      isNumericString
      onChange={(event) => handleCredit(event)}
      prefix="R$ "
    />
  );
}
