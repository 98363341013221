import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_AUTH } from '../../routes/paths';

// ----------------------------------------------------------------------

type AuthProtectProps = {
  children: ReactNode;
};

export default function AuthProtect({ children }: AuthProtectProps) {
  const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated')!);

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}
