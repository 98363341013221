import { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { PATH_HOME } from '../routes/paths';

const { HomeNavbar } = ComponentsLib.Layouts.Dashboard;

// ----------------------------------------------------------------------

type HomeLayoutProps = {
  children: ReactNode;
};

export function HomeLayout({ children }: HomeLayoutProps) {
  return (
    <Box sx={{ height: '100%' }}>
      <HomeNavbar homePath={PATH_HOME} />
      <Box sx={{ height: '100%' }}>{children}</Box>
    </Box>
  );
}
