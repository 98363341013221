import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { deleteTransaction } from '../../utils/paymentsServices';
import { resetDataList } from '../../redux/slices/global';
import { PATH_DASHBOARD } from '../../routes/paths';
import { RootState } from '../../redux/store';
import { Messages } from '../../@types/messages';

type DialogToSaveStates = {
  openDialog: any;
  setOpenDialog(state: boolean): void;
};

type ParamTypes = {
  id: string;
};

export function TransactionsDialogToCancel({ openDialog, setOpenDialog }: DialogToSaveStates) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const { isRequestingTransactions } = useSelector((state: RootState) => state.transactions);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [requestingSave, setRequestingSave] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value: any) => {
    if (!value) {
      setPasswordError('Senha inválida');
    } else {
      setPasswordError('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    validatePassword(value);
  };

  const handleSave = async () => {
    setRequestingSave(true);
    try {
      await deleteTransaction({ id, password });
      setOpenDialog(false);
      setRequestingSave(false);
      enqueueSnackbar(Messages.TRANSACTION_CANCEL_SUCCESS, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      void dispatch(resetDataList(true));
      history.push(PATH_DASHBOARD.general.app);
    } catch (error: any) {
      setRequestingSave(false);
      enqueueSnackbar(Messages[error.message], {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  };

  useEffect(() => {
    if (isRequestingTransactions === false) {
      setRequestingSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingTransactions]);

  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Box>
        <DialogTitle id="form-dialog-title">Cancelar transação</DialogTitle>
        <DialogContent sx={{ pb: 0, minHeight: '191px', maxWidth: '374px' }}>
          <DialogContentText sx={{ mb: 4 }}>
            O cancelamento não poderá ser desfeito. Digite a sua senha Payface para confirmar
          </DialogContentText>
          <TextField
            sx={{ width: '100%' }}
            required={true}
            name="password"
            id="password"
            label="Sua senha Payface ツ"
            variant="outlined"
            error={!!passwordError}
            helperText={passwordError}
            type={showPassword ? 'number' : 'password'}
            onChange={(event) => handleChange(event)}
            inputProps={{
              maxLength: 4,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickPassword}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            disabled={password.length < 4}
            onClick={handleSave}
            color="primary"
            pending={requestingSave}
          >
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
