function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  transactions: '/transactions',
  client: '/client',
  create: '/client/create',
  createClient: '/client/create',
  identifications: '/identification',
  apikeys: '/apykeys',
  devices: '/devices',
  deviceCreate: '/devices/create',
  page404: '/404',
  page500: '/500',
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  components: '/components',
  dashboard: ROOTS_DASHBOARD,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    transaction: path(ROOTS_DASHBOARD, '/app/:id'),
  },
  employees: {
    root: path(ROOTS_DASHBOARD, '/employees'),
    form: path(ROOTS_DASHBOARD, '/employees/create'),
  },
  clients: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    invite: path(ROOTS_DASHBOARD, '/clients/invite'),
    client: path(ROOTS_DASHBOARD, '/clients/:id'),
  },
  identifications: {
    root: path(ROOTS_DASHBOARD, '/identification'),
  },
  cards: {
    root: path(ROOTS_DASHBOARD, '/cards'),
    create: path(ROOTS_DASHBOARD, '/cards/create'),
  },
  credits: {
    root: path(ROOTS_DASHBOARD, '/credits'),
  },
  apikeys: {
    root: path(ROOTS_DASHBOARD, '/api-keys'),
  },
  devices: {
    root: path(ROOTS_DASHBOARD, '/devices'),
    create: path(ROOTS_DASHBOARD, '/devices/create'),
    edit: path(ROOTS_DASHBOARD, '/devices/:id'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/card'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  components: {
    root: path(ROOTS_DASHBOARD, '/components'),
    accordion: path(ROOTS_DASHBOARD, '/components/accordion'),
    alert: path(ROOTS_DASHBOARD, '/components/alert'),
    autocomplete: path(ROOTS_DASHBOARD, '/components/autocomplete'),
    avatar: path(ROOTS_DASHBOARD, '/components/avatars'),
    badge: path(ROOTS_DASHBOARD, '/components/badges'),
    breadcrumbs: path(ROOTS_DASHBOARD, '/components/breadcrumbs'),
    buttons: path(ROOTS_DASHBOARD, '/components/buttons'),
    chip: path(ROOTS_DASHBOARD, '/components/chips'),
    dialog: path(ROOTS_DASHBOARD, '/components/dialogs'),
    textfield: path(ROOTS_DASHBOARD, '/components/text-fields'),
    label: path(ROOTS_DASHBOARD, '/components/labels'),
    lists: path(ROOTS_DASHBOARD, '/components/lists'),
    menu: path(ROOTS_DASHBOARD, '/components/menu'),
    pagination: path(ROOTS_DASHBOARD, '/components/pagination'),
    pickers: path(ROOTS_DASHBOARD, '/components/pickers'),
    popover: path(ROOTS_DASHBOARD, '/components/popover'),
    progress: path(ROOTS_DASHBOARD, '/components/progress'),
    rating: path(ROOTS_DASHBOARD, '/components/rating'),
    selectionControls: path(ROOTS_DASHBOARD, '/components/selection-controls'),
    snackbar: path(ROOTS_DASHBOARD, '/components/snackbars'),
    slider: path(ROOTS_DASHBOARD, '/components/slider'),
    stepper: path(ROOTS_DASHBOARD, '/components/steppers'),
    tabs: path(ROOTS_DASHBOARD, '/components/tabs'),
    table: path(ROOTS_DASHBOARD, '/components/table'),
    timeline: path(ROOTS_DASHBOARD, '/components/timeline'),
    tooltip: path(ROOTS_DASHBOARD, '/components/tooltips'),
    transferList: path(ROOTS_DASHBOARD, '/components/transfer-list'),
    treeView: path(ROOTS_DASHBOARD, '/components/tree-view'),
    extraComponents: path(ROOTS_DASHBOARD, '/extra-components'),
    chart: path(ROOTS_DASHBOARD, '/extra-components/chart'),
    map: path(ROOTS_DASHBOARD, '/extra-components/map'),
    editor: path(ROOTS_DASHBOARD, '/extra-components/editor'),
    copyToClipboard: path(ROOTS_DASHBOARD, '/extra-components/copy-to-clipboard'),
    upload: path(ROOTS_DASHBOARD, '/extra-components/upload'),
    multiLanguage: path(ROOTS_DASHBOARD, '/extra-components/multi-language'),
    animate: path(ROOTS_DASHBOARD, '/extra-components/animate'),
  },
};
