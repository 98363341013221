import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { disableEmployee } from '../../utils/employeeServices';
import { resetUpdatedPermissions } from '../../redux/slices/employees';
import { RootState } from '../../redux/store';
import { Messages } from '../../@types/messages';

type DialogToSaveStates = {
  openDialog: any;
  setOpenDialog(state: object): void;
};

export function DialogToDisable({ openDialog, setOpenDialog }: DialogToSaveStates) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [requestingDisable, setRequestingDisable] = useState(false);
  const { isRequestingEmployees } = useSelector((state: RootState) => state.employees);
  const { status, original } = openDialog;

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog({ ...openDialog, status: false });
  };

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value: any) => {
    if (!value) {
      setPasswordError('Senha inválida');
    } else {
      setPasswordError('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    validatePassword(value);
  };

  const disabled = original.deleted === false;

  const handleSave = async () => {
    setRequestingDisable(true);
    try {
      await disableEmployee({ password, original, disabled });
      setOpenDialog({ ...openDialog, status: false });
      void dispatch(resetUpdatedPermissions(true));
      enqueueSnackbar(disabled ? Messages.EMPLPLOYER_DEACTIVATE_SUCCESS : Messages.EMPLPLOYER_ACTIVATE_SUCCESS, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      setRequestingDisable(false);
    } catch (error: any) {
      setRequestingDisable(false);
      enqueueSnackbar(Messages[error.message], {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  };

  useEffect(() => {
    if (isRequestingEmployees === false) {
      setRequestingDisable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingEmployees]);

  return (
    <Dialog open={status} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Box>
        <DialogTitle id="form-dialog-title">
          {original.deleted === false ? 'Desativar acesso' : 'Ativar acesso'}
        </DialogTitle>
        <DialogContent sx={{ pb: 0, minHeight: '191px', maxWidth: '374px' }}>
          <DialogContentText sx={{ mb: 4 }}>
            {original.deleted === false
              ? `Gostaria de desativar o acesso para ${original.firstName} ${original.lastName}?`
              : `Gostaria de ativar o acesso para ${original.firstName} ${original.lastName}?`}
          </DialogContentText>
          <TextField
            required={true}
            name="password"
            id="password"
            label="Sua senha Payface ツ"
            variant="outlined"
            error={!!passwordError}
            helperText={passwordError}
            type={showPassword ? 'number' : 'password'}
            onChange={(event) => handleChange(event)}
            inputProps={{
              maxLength: 4,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickPassword}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            disabled={password.length < 4}
            onClick={handleSave}
            color="primary"
            pending={requestingDisable}
          >
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
