import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TablePagination,
  TableSortLabel,
  Skeleton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { RootState } from '../../redux/store';
import { getApiKeysByPlace, updateDirectionOption } from '../../redux/slices/apiKeys';

// ----------------------------------------------------------------------

const columns = [
  { id: 'deleted', label: '', align: 'left' },
  { id: 'name', label: 'Nome', minWidth: 180, align: 'left' },
  { id: 'createdAt', label: 'Data de criação', minWidth: 120, align: 'left' },
  { id: 'expiresAt', label: 'Data de expiração', minWidth: 120, align: 'left' },
  { id: 'rate', label: 'Rate Limit', minWidth: 150, align: 'center' },
  { id: 'key', label: 'Key', minWidth: 240, align: 'left' },
];

export function ApiKeysDataList() {
  const dispatch = useDispatch();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);

  const { apikeys, _order, headerCount, isRequestingApiKeys } = useSelector((state: RootState) => state.apiKeys);
  const minArrayLength = 0;

  const renderStatusIcon = (deleted: boolean) => {
    if (!deleted)
      return (
        <Tooltip title="Ativa" color="primary">
          <CheckCircleOutlineIcon color="primary" />
        </Tooltip>
      );
    return (
      <Tooltip title="Deletada" color="error">
        <DeleteOutlineIcon color="error" />
      </Tooltip>
    );
  };

  const apiKeysData = isRequestingApiKeys
    ? [...Array(initialRows)].map(() => ({
        name: <Skeleton animation="wave" variant="rectangular" width={180} height={30} />,
        deleted: <Skeleton animation="wave" variant="rectangular" width={180} height={30} />,
        key: <Skeleton animation="wave" variant="rectangular" width={240} height={30} />,
        rate: <Skeleton animation="wave" variant="rectangular" width={150} height={30} />,
        createdAt: <Skeleton animation="wave" variant="rectangular" width={120} height={30} />,
        expiresAt: <Skeleton animation="wave" variant="rectangular" width={120} height={30} />,
      }))
    : apikeys.map((row) => {
        const created = moment(row.createdAt).format('DD/MM/YYYY HH:MM:SS');
        const expires = moment(row.expiresAt).format('DD/MM/YYYY HH:MM:SS');
        return {
          id: row.id,
          name: row.name,
          deleted: renderStatusIcon(row.deleted),
          key: row.key,
          rate: row.rate ? 'row.rate' : '-',
          createdAt: created,
          expiresAt: expires,
          original: row,
        };
      });

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = () => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
  };

  useEffect(() => {
    dispatch(updateDirectionOption(direction));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    const _sort = 'createdAt';
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(getApiKeysByPlace({ _end: end, _order, _sort, _start: start }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {apiKeysData.length === minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.label === 'Data' ? (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                    <TableSortLabel
                      active={true}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={handleDirection}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeysData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return <TableCell key={column.id}>{value}</TableCell>;
                  })}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
