import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, Typography } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { getApiKeysByPlace } from '../../redux/slices/apiKeys';
import { ApiKeysDataList } from '../../components/_api-keys/ApiKeysDataList';
import { RootState } from '../../redux/store';
import { resetDataList } from '../../redux/slices/global';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

export function ApiKeys() {
  const dispatch = useDispatch();
  const { _order, _end, _start } = useSelector((state: RootState) => state.apiKeys);
  const { reset } = useSelector((state: RootState) => state.global);

  useEffect(() => {
    const sort = 'createdAt';
    if (reset === true) {
      void dispatch(resetDataList(false));
    }
    void dispatch(getApiKeysByPlace({ _end, _order, _sort: sort, _start }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_order, reset]);

  return (
    <Page title="CRM > Chaves de API">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Chaves de API
        </Typography>
        <Card sx={{ px: 1, py: 2 }}>
          <ApiKeysDataList />
        </Card>
      </Container>
    </Page>
  );
}
