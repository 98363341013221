class Permissions {
  static hasFeature = (feature: string, featurable: Featurable = Permissions.getPlace()) => {
    let featureList: { name: string }[] = [];
    if (featurable) {
      const { placeFeatures } = featurable;
      featureList = placeFeatures;
      if (featureList?.find((item: any) => item.name === feature)) {
        return true;
      }
      return false;
    }
    return false;
  };

  static hasPermission = (permission: string, featurable: Featurable = Permissions.getPlace()) => {
    let permissionList: string[] = [];
    if (featurable) {
      const { permissions } = featurable;
      permissionList = permissions;
      if (permissionList?.find((item: any) => item === permission)) {
        return true;
      }
      return false;
    }
    return false;
  };

  static getPlace = () => {
    const user = JSON.parse(localStorage.getItem('@admin:user')!);
    if (user) {
      const pName = localStorage.getItem('place-id')
        ? JSON.parse(localStorage.getItem('place-id')!)
        : user.employments[0].placeName;
      const place = user.employments.find((place: any) => place.placeName === pName);
      return place;
    }
  };
}

interface Featurable {
  placeFeatures: { name: string }[];
  permissions: string[];
}

export default Permissions;
