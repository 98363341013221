import { Chip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function StatusChip({ status }: { status: string }) {
  const statusReferences = {
    DEFAULT: (
      <Chip
        variant="outlined"
        icon={<ErrorIcon />}
        sx={{ borderColor: 'grey.main', color: 'grey.main' }}
        label="Desconhecido"
      />
    ),
    SUCCESS: <Chip variant="outlined" icon={<CheckCircleIcon />} color="primary" label="Efetivado" />,
    CANCELED: (
      <Chip
        variant="outlined"
        icon={<CancelIcon />}
        sx={{ borderColor: 'error.main', color: 'error.main' }}
        label="Cancelado"
      />
    ),
    FAIL: (
      <Chip
        variant="outlined"
        icon={<ErrorIcon />}
        sx={{ borderColor: 'warning.main', color: 'warning.main' }}
        label="Não efetivado"
      />
    ),
  };

  return statusReferences[status] || statusReferences.DEFAULT;
}
