import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { api } from '../../utils/axios';
import { store } from '../store';

const apiKeysEndpoints = {
  apikeys: '/places-api-keys',
};

type ApiKeysStates = {
  isRequestingApiKeys: boolean;
  errorMessageApiKeys: string;
  apikeys: any;
  _order: string;
  _end: number;
  _start: number;
  headerCount: number;
  updated: boolean;
  resetPagination: boolean;
};

const initialState: ApiKeysStates = {
  isRequestingApiKeys: false,
  errorMessageApiKeys: '',
  apikeys: [],
  _order: 'DESC',
  _end: 10,
  _start: 0,
  headerCount: 0,
  updated: false,
  resetPagination: false,
};

const slice = createSlice({
  name: 'apikeys',
  initialState,
  reducers: {
    requestingApiKeys(state) {
      state.isRequestingApiKeys = true;
    },
    getApiKeysSuccess(state, action) {
      state.isRequestingApiKeys = false;
      state.apikeys = action.payload;
    },
    getApiKeysFail(state, action) {
      state.isRequestingApiKeys = false;
      state.errorMessageApiKeys = action.payload;
    },
    getOrder(state, action) {
      state._order = action.payload;
    },
    getPaginationStartAndEnd(state, action) {
      const { start, end } = action.payload;
      state._end = end;
      start._start = start;
    },
    getApiKeysHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
  },
});

export default slice.reducer;

export function getApiKeysByPlace({
  _end,
  _order,
  _sort,
  _start,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingApiKeys());

    try {
      const queryStrings: any = {
        _end,
        _order,
        _sort,
        _start,
      };

      const response: void | AxiosResponse<any> = await api.get(apiKeysEndpoints.apikeys, {
        params: queryStrings,
      });

      const apikeys = response?.data;

      dispatch(slice.actions.getApiKeysSuccess(apikeys));
      dispatch(slice.actions.getApiKeysHeaders(Number(response?.headers['x-total-count'])));
    } catch (error) {
      dispatch(slice.actions.getApiKeysFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.getOrder(order));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}
