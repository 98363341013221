import axios from 'axios';
import { Employment } from '../@types/user';
import { url } from '../config';

// ----------------------------------------------------------------------

export const api = axios.create({
  baseURL: url || 'http://127.0.0.1:3000/crm',
});

api.interceptors.request.use((request) => {
  if (request?.headers) {
    const accessToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('@admin:user')!);

    if (accessToken) {
      request.headers.authorization = `bearer ${JSON.parse(accessToken)}`;
    } else {
      delete request.headers.authorization;
    }

    if (user) {
      const placeName = JSON.parse(localStorage.getItem('place-id')!);
      const { placeId } = user.employments.find((place: Employment) => place.placeName === placeName);

      if (placeId && request.url !== '/profile') {
        request.headers.common['x-place-id'] = placeId;
      } else {
        delete request.headers.common['x-place-id'];
      }
    }
  }

  return request;
});
