import { useEffect, useState } from 'react';
import { Box, Button, FormControl, TextField, Skeleton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { addDevice, updateDeviceById } from '../../utils/devicesServices';
import { PATH_DASHBOARD } from '../../routes/paths';
import { QRCodeField } from '../library/QRCodeField';
import { RootState } from '../../redux/store';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  formButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type DevicesQRCodeFormProps = {
  type: string;
};

export function DevicesQRCodeForm({ type }: DevicesQRCodeFormProps) {
  const history = useHistory();
  const classes = useStyles();
  const [requestingCreate, setRequestingCreate] = useState(false);
  const { device, isRequestingDevice } = useSelector((state: RootState) => state.devices);
  const [deviceParams, setDeviceParams]: any = useState({
    name: '',
    uuid: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleBackCardsPage = () => {
    history.push(PATH_DASHBOARD.devices.root);
  };

  const handleNewDevice = async () => {
    setRequestingCreate(true);
    try {
      if (type === 'create') {
        await addDevice({ deviceUUID: deviceParams.uuid, name: deviceParams.name });
      } else {
        await updateDeviceById({ name: deviceParams.name, deviceUUID: deviceParams.uuid, original: device });
      }
      setRequestingCreate(false);
      enqueueSnackbar(`Dispositivo ${type === 'create' ? 'adicionado' : 'alterado'} com sucesso`, {
        variant: 'success',
      });
      history.push(PATH_DASHBOARD.devices.root);
    } catch (error) {
      setRequestingCreate(false);
      enqueueSnackbar(`Não foi possível ${type === 'create' ? 'adicionar' : 'alterar'} o dispositivo`, {
        variant: 'error',
      });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setDeviceParams({ ...deviceParams, [name]: value });
  };

  useEffect(() => {
    if (type === 'create') {
      setDeviceParams({ ...deviceParams, uuid: uuidv4() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === 'edit' && device.deviceUUID) {
      setDeviceParams({ name: device.name, uuid: device.deviceUUID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  return (
    <Box sx={{ mb: 1, mt: 1, display: 'flex' }}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <FormControl sx={{ marginRight: 2 }}>
          {isRequestingDevice ? (
            <Skeleton sx={{ mb: 4 }} animation="wave" variant="rectangular" width={400} height={30} />
          ) : (
            <TextField
              required
              sx={{ width: '400px', mb: 4 }}
              name="name"
              id="name"
              label="Nome do dispositivo"
              type="text"
              value={deviceParams.name}
              variant="outlined"
              onChange={(event) => handleChange(event)}
            />
          )}
        </FormControl>
        <FormControl sx={{ marginRight: 2 }}>
          {isRequestingDevice ? (
            <Skeleton animation="wave" variant="rectangular" width={400} height={30} />
          ) : (
            <TextField
              required
              sx={{ width: '400px' }}
              name="uuid"
              id="uuid"
              label="UUID do Dispositivo"
              type="text"
              value={deviceParams.uuid}
              variant="outlined"
              onChange={(event) => handleChange(event)}
            />
          )}
        </FormControl>
        <QRCodeField setParams={setDeviceParams} params={deviceParams} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 3,
          }}
        >
          <Button
            type="button"
            color="primary"
            variant="outlined"
            size="medium"
            startIcon={<ArrowBackIcon />}
            className={classes.formButton}
            onClick={handleBackCardsPage}
          >
            Voltar
          </Button>
          <LoadingButton
            sx={{ ml: 1 }}
            type="submit"
            color="primary"
            variant="contained"
            size="medium"
            startIcon={<SaveIcon />}
            className={classes.formButton}
            disabled={!!(!deviceParams.name || !deviceParams.uuid)}
            onClick={handleNewDevice}
            pending={requestingCreate}
          >
            Salvar
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
