import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  formButton: {
    margin: theme.spacing(2),
  },
}));

type FilterButtonsProps = {
  initialState: any;
  filterRequesting: boolean;
  clearRequesting: boolean;
  setFilter: any;
  setClearRequesting: any;
  formId: string;
  updateFilter: any;
  resetPagination(state: any): () => Promise<void>;
};

export function FilterButtons({
  initialState,
  filterRequesting,
  clearRequesting,
  setFilter,
  setClearRequesting,
  formId,
  updateFilter,
  resetPagination,
}: FilterButtonsProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = () => {
    setFilter(initialState);
    dispatch(resetPagination(true));
    dispatch(updateFilter(initialState));
    setClearRequesting(true);
  };

  return (
    <>
      <LoadingButton
        form={formId}
        type="submit"
        color="primary"
        variant="outlined"
        size="medium"
        startIcon={<FilterListIcon />}
        className={classes.formButton}
        pending={filterRequesting}
      >
        Filtrar
      </LoadingButton>
      <LoadingButton
        type="button"
        onClick={handleClick}
        color="error"
        variant="outlined"
        size="medium"
        className={classes.formButton}
        startIcon={<ClearIcon />}
        pending={clearRequesting}
      >
        Limpar filtro
      </LoadingButton>
    </>
  );
}
