import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Typography, Grid } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { CreditsDataList } from '../../components/_credits/CreditsDataList';
import { getCreditsByPlace } from '../../redux/slices/credits';
import { RootState } from '../../redux/store';
import { resetDataList } from '../../redux/slices/global';
import { PATH_DASHBOARD } from '../../routes/paths';
import { CreditsFormFilter } from '../../components/_credits/CreditsFormFilter';

const { Page } = ComponentsLib.Generics;

export function Credits() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { _end, _order, _start, _sort, period, type, resetPagination, cpf } = useSelector(
    (state: RootState) => state.credits
  );
  const { reset } = useSelector((state: RootState) => state.global);

  useEffect(() => {
    if (reset) {
      dispatch(resetDataList(false));
    }
    dispatch(getCreditsByPlace({ _end, _order, _sort, _start, userId: '', user: '', period, type, cpf }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_order, reset, period, type, cpf, resetPagination]);

  useEffect(() => {
    if (reset === true) {
      history.push(PATH_DASHBOARD.credits.root);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Page title="CRM > Créditos">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Créditos
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <CreditsFormFilter />
              <CreditsDataList view="credits" />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
