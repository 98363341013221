import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, Select, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateFilterOptions, resetPaginationData } from '../../redux/slices/transactions';
import { resetDataList } from '../../redux/slices/global';
import { PATH_DASHBOARD } from '../../routes/paths';
import Permissions from '../../hooks/Permissions';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
}));

export function SelectPlaceId() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const firstPlace = 0;
  const user = JSON.parse(localStorage.getItem('@admin:user')!);
  const initialPlaceState = localStorage.getItem('place-id')
    ? JSON.parse(localStorage.getItem('place-id')!)
    : user.employments[firstPlace].placeName;
  const classes = useStyles();
  const [place, setPlace] = useState(initialPlaceState);
  const [places, setPlaces] = useState([]);

  const renderList = (list) =>
    list.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));

  useEffect(() => {
    const mapPlacesNames = user.employments.map((place) => place.placeName);
    setPlaces(mapPlacesNames);
    if (!localStorage.getItem('place-id')!) {
      localStorage.setItem('place-id', JSON.stringify(place));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstViewPathByPermission = (): string => {
    const pathsByPermission = {
      CAN_MANAGE_PAYMENT: PATH_DASHBOARD.general.app,
      CAN_MANAGE_USER: PATH_DASHBOARD.employees.root,
      CAN_MANAGE_POST_PRE: PATH_DASHBOARD.cards.root,
      CAN_MANAGE_DEVICE: PATH_DASHBOARD.devices.root,
      CAN_MANAGE_CLIENT: PATH_DASHBOARD.clients.root,
      CAN_MANAGE_KEY: PATH_DASHBOARD.apikeys.root,
    };
    const keys = Object.keys(pathsByPermission);
    const currentValidPath = keys.find(
      (key) => location.pathname.includes(pathsByPermission[key]) && Permissions.hasPermission(key)
    );
    if (currentValidPath) {
      return pathsByPermission[currentValidPath];
    }
    for (let i = 0; i < keys.length; i++) {
      if (Permissions.hasPermission(keys[i])) {
        return pathsByPermission[keys[i]];
      }
    }
    return PATH_DASHBOARD.root;
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPlace(value);
    localStorage.setItem('place-id', JSON.stringify(value));
    dispatch(
      updateFilterOptions({
        period: 'ALL',
        status: 'ALL',
        flag: 'ALL',
        product: 'ALL',
        client: '',
      })
    );
    history.push(firstViewPathByPermission());
    window.location.reload();
    void dispatch(resetDataList(true));
    dispatch(resetPaginationData(true));
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
      <FormControl variant="outlined" className={classes.formControl} sx={{ width: '100%' }}>
        <Select
          labelId="place-label"
          id="place"
          name="place"
          value={place || ''}
          onChange={(event) => handleChange(event)}
          displayEmpty
        >
          {renderList(places)}
        </Select>
      </FormControl>
    </Box>
  );
}
