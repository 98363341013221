import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from './paths';
import AuthGuard from '../components/guards/AuthGuard';
import { DashboardLayout } from '../home/DashboardLayout';
import { Transactions } from '../views/transactions/Transactions';
import { CreatePage } from '../components/library/CreatePage';
import { Employees } from '../views/employees/Employees';
import { Cards } from '../views/cards';
import { Clients, ClientInvite, ClientsDetails } from '../views/clients';
import Identifications from '../views/identifications/Identifications';
import { Credits } from '../views/credit';
import { ApiKeys } from '../views/api-keys';
import { Devices } from '../views/devices';
import { DevicesEditPage } from '../components/_devices/DevicesEditPage';

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_DASHBOARD.general.app,
      component: () => <Transactions />,
    },
    {
      path: PATH_DASHBOARD.general.transaction,
      component: lazy(() => import('../views/transactions/TransactionDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.app} />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.employees.root,
      component: () => <Employees />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.employees.form,
      component: () => <CreatePage title="Adicionar funcionário" form="employees" />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.clients.root,
      component: () => <Clients />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.identifications.root,
      component: () => <Identifications />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.clients.invite,
      component: () => <ClientInvite />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.cards.root,
      component: () => <Cards />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.cards.create,
      component: () => <CreatePage title="Adicionar cartão" form="cards" />,
    },
    {
      path: PATH_DASHBOARD.clients.client,
      component: () => <ClientsDetails />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.credits.root,
      component: () => <Credits />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.apikeys.root,
      component: () => <ApiKeys />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.devices.root,
      component: () => <Devices />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.devices.create,
      component: () => <CreatePage title="Adicionar dispositivo" form="devices" />,
    },
    {
      path: PATH_DASHBOARD.devices.edit,
      component: () => <DevicesEditPage />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.root,
      component: () => <Redirect to={PATH_DASHBOARD.user.profile} />,
    },
    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default DashboardRoutes;
