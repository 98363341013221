import { Redirect } from 'react-router-dom';
import { PATH_AUTH } from './paths';
import { HomeLayout } from '../home/HomeLayout';

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: () => <Redirect from="/" to={PATH_AUTH.login} />,
    },
    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default HomeRoutes;
