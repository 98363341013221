import { ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@material-ui/core';
import { Icon } from '@iconify/react';
import baselineCreditCard from '@iconify/icons-ic/baseline-credit-card';
import { ComponentsLib } from '@payface-frontends-libs/components';
import roundAccountBalanceWallet from '@iconify/icons-ic/round-account-balance-wallet';
import baselineCardMembership from '@iconify/icons-ic/baseline-card-membership';
import outlineRequestQuote from '@iconify/icons-ic/outline-request-quote';
import baselinePeople from '@iconify/icons-ic/baseline-people';
import baselineDevices from '@iconify/icons-ic/baseline-devices';
import outlineVpnKey from '@iconify/icons-ic/outline-vpn-key';
import baselineSwitchAccount from '@iconify/icons-ic/baseline-switch-account';
import baselineFace from '@iconify/icons-ic/baseline-face';
import baselineList from '@iconify/icons-ic/baseline-list';
import { RootState } from '../redux/store';
import { PATH_DASHBOARD } from '../routes/paths';
import Permissions from '../hooks/Permissions';

const {
  Layouts: {
    Dashboard: { SidebarItem },
  },
} = ComponentsLib;

const { SvgIconStyle } = ComponentsLib.Generics;
const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: 22, height: 22 }} />
);
const getIconifyIcon = (name: object) => <Icon icon={name} width="22" height="22" />;

const ICONS = {
  analytics: getIcon('ic_analytics'),
  card: getIconifyIcon(baselineCreditCard),
  clients: getIconifyIcon(baselinePeople),
  credit: getIconifyIcon(baselineCardMembership),
  dashboard: getIconifyIcon(outlineRequestQuote),
  devices: getIconifyIcon(baselineDevices),
  employees: getIconifyIcon(baselineSwitchAccount),
  financial: getIconifyIcon(roundAccountBalanceWallet),
  identification: getIconifyIcon(baselineFace),
  key: getIconifyIcon(outlineVpnKey),
  transactions: getIconifyIcon(baselineList),
};

// ----------------------------------------------------------------------

type TNavItem = {
  icon?: ReactNode;
  info?: ReactNode;
  href: any;
  title: any;
  items?: TNavItem[];
};

type ReduceChildParams = {
  array: ReactNode[];
  item: TNavItem;
  pathname: string;
  level: number;
};

function reduceChild({ array, item, pathname, level }: ReduceChildParams) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </SidebarItem>,
    ];
  }
  return [
    ...array,
    <SidebarItem key={key} level={level} href={item.href} icon={item.icon} info={item.info} title={item.title} />,
  ];
}

type RenderSidebarItemsParams = {
  items: TNavItem[];
  pathname: string;
  level?: number;
};

function renderSidebarItems({ items, pathname, level = 0 }: RenderSidebarItemsParams) {
  return (
    <List disablePadding>
      {items?.reduce<ReactNode[]>((array, item) => reduceChild({ array, item, pathname, level }), [])}
    </List>
  );
}

const SidebarConfigComponent = () => {
  const { reload } = useSelector((state: RootState) => state.global);
  const [reloadMenu, setReloadMenu] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setReloadMenu(!reloadMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const allNavItems = [
    {
      permission: 'CAN_MANAGE_PAYMENT',
      title: 'Pagamentos',
      href: PATH_DASHBOARD.general.app,
      icon: ICONS.dashboard,
    },
    {
      permission: 'CAN_MANAGE_PAYMENT',
      title: 'Identificações',
      href: PATH_DASHBOARD.identifications.root,
      icon: ICONS.identification,
    },
    {
      permission: 'CAN_MANAGE_CLIENT',
      title: 'Clientes',
      href: PATH_DASHBOARD.clients.root,
      icon: ICONS.clients,
    },
    {
      permission: 'CAN_MANAGE_KEY',
      title: 'Chaves de API',
      href: PATH_DASHBOARD.apikeys.root,
      icon: ICONS.key,
    },
    {
      permission: 'CAN_MANAGE_DEVICE',
      title: 'Dispositivos',
      href: PATH_DASHBOARD.devices.root,
      icon: ICONS.devices,
    },
    {
      permission: 'CAN_MANAGE_POST_PRE',
      feature: 'HAS_POST_PAID',
      title: 'Cartões',
      href: PATH_DASHBOARD.cards.root,
      icon: ICONS.card,
    },
    {
      permission: 'CAN_MANAGE_POST_PRE',
      feature: 'HAS_POST_PAID',
      title: 'Créditos',
      href: PATH_DASHBOARD.credits.root,
      icon: ICONS.credit,
    },
    {
      permission: 'CAN_MANAGE_USER',
      title: 'Funcionários',
      href: PATH_DASHBOARD.employees.root,
      icon: ICONS.employees,
    },
  ];

  type menuItems = {
    subheader: string;
    items: any;
  };

  const place = Permissions.getPlace();

  // eslint-disable-next-line @typescript-eslint/no-redeclare
  const menuItems = [
    {
      subheader: 'geral',
      items: allNavItems
        .filter((item) => {
          const hasPermission = Permissions.hasPermission(item.permission, place);
          if (item.feature) {
            const hasFeature = Permissions.hasFeature(item.feature, place);
            return hasFeature && hasPermission;
          }
          return hasPermission;
        })
        .map((item) => ({
          title: item.title,
          href: item.href,
          icon: item.icon,
        })),
    },
  ] as any;

  // eslint-disable-next-line arrow-body-style
  const renderMenu = () => {
    return (
      <>
        {menuItems.map((list) => (
          <List
            disablePadding
            key={list.subheader}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  mt: 3,
                  mb: 2,
                  pl: 5,
                  color: 'text.primary',
                  typography: 'overline',
                }}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderSidebarItems({
              items: list.items,
              pathname,
            })}
          </List>
        ))}
      </>
    );
  };

  return <>{renderMenu()}</>;
};

export default SidebarConfigComponent;
