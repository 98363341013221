import ReactApexChart from 'react-apexcharts';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { merge } from '../../utils/utils';

const { BaseOptionChart } = ComponentsLib.Charts;

type ChartDonutProps = {
  series: any;
  labels: any;
};

export function ChartDonut({ series, labels }: ChartDonutProps) {
  const chartOptions = merge(BaseOptionChart(), {
    labels,
    stroke: { show: false },
    legend: { position: 'right', horizontalAlign: 'center', itemMargin: { horizontal: 50, vertical: 5 } },
    plotOptions: { pie: { donut: { size: '80%' } } },
    colors: ['#AA00FF', '#00838F', '#FF4842', '#FFC107'],
  });

  return <ReactApexChart data-testid="apex-chart" type="donut" series={series} options={chartOptions} height="80%" />;
}
