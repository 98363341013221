import { AxiosResponse } from 'axios';
import { api } from './axios';
import { MessageString } from '../@types/messages';

const TRANSACTION_ENDPOINT = '/transactions';

const getFormattedOperations = (months, list) => {
  const newList: any = [];
  const noValueFound = 0;
  months.forEach((month) => {
    const dataFound = list.find((value) => value.name === month);
    if (dataFound) {
      newList.push(dataFound.balance);
    } else {
      newList.push(noValueFound);
    }
  });
  return newList;
};

const getInfoByMonth = (report: any) => {
  const { transactions, failed, cancelled } = report;
  const emptyArray = 0;

  if (Object.keys(report).length !== emptyArray) {
    const months = transactions.dataPoints.map((data) => data.name);
    const resultSuccess = getFormattedOperations(months, transactions.dataPoints);
    const resultCancelled = getFormattedOperations(months, cancelled.dataPoints);
    const resultFail = getFormattedOperations(months, failed.dataPoints);

    return {
      response: {
        data: [
          {
            name: 'Efetivados',
            type: 'column',
            data: resultSuccess,
          },
          {
            name: 'Cancelados',
            type: 'column',
            data: resultCancelled,
          },
          {
            name: 'Não efetivados',
            type: 'column',
            data: resultFail,
          },
        ],
        categories: months,
        labels: months,
      },
    };
  }
  return {};
};

export const getFormattedChartData = (report) => getInfoByMonth(report);

export async function deleteTransaction({ id, password }: { id: string; password: string }) {
  const queryStrings = {
    pin: password,
  };
  await api
    .delete(`${TRANSACTION_ENDPOINT}/${id}`, {
      params: queryStrings,
    })
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
}

export async function getCsvDataTransactions({
  _end,
  _order,
  _sort,
  _start,
  period,
  authorizer,
  status,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  period: string;
  authorizer: string;
  status: string;
}) {
  const queryStrings: any = {
    _end,
    _order,
    _sort,
    _start,
    period,
  };

  authorizer !== 'ALL' && (queryStrings.authorizer = authorizer);
  status !== 'ALL' && (queryStrings.status = status);

  const response: void | AxiosResponse<any> = await api
    .get(TRANSACTION_ENDPOINT, {
      params: queryStrings,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
  return response?.data;
}
