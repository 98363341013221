import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Grid, Typography } from '@material-ui/core';

import moment from 'moment';

import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import PostPaidCard from '../Cards/PostPaidCard';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[600],
  },
}));

export function TabOne() {
  const classes = useStyles();
  const { client } = useSelector((state: RootState) => state.clients);

  const typeCard = { type: 'POST_PAID' };

  const [dataInfo, setDataInfo] = useState({
    date: '',
    client: '',
    amount: '',
    installments: '',
    paymentType: '',
    flag: '',
    status: '',
    id: '',
  });

  useEffect(() => {
    if (client) {
      const formattedDate = moment(client.createdAt).format('DD/MM/YYYY');
      const formattedClient = `${client.firstName} ${client.lastName}`;

      setDataInfo({
        ...dataInfo,
        date: formattedDate,
        client: formattedClient,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <Card>
      <CardHeader title="Resumo da transação" />
      <CardContent>
        <Grid container spacing={0} sx={{ mt: 1, mb: 3, mx: 0 }}>
          <Grid item xs={12} md={4} sx={{ mb: 3 }}>
            <Typography variant="caption" className={classes.label}>
              Nome
            </Typography>
            <Typography variant="body1">{dataInfo.date}</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 3 }}>
            <Typography variant="caption" className={classes.label}>
              Cliente desde
            </Typography>
            <Typography variant="body1">{dataInfo.client}</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 3 }}>
            <Typography variant="caption" className={classes.label}>
              Parcelas
            </Typography>
            <Typography variant="body1">{dataInfo.installments}</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 3 }}>
            <Typography variant="caption" className={classes.label}>
              Cartões
            </Typography>
            <PostPaidCard props={typeCard} />
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 3 }}>
            <Typography variant="caption" className={classes.label}>
              Créditos
            </Typography>
            <Typography variant="body1">{dataInfo.id}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
