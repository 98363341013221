import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

type GuestProtectProps = {
  children: ReactNode;
};

export default function GuestProtect({ children }: GuestProtectProps) {
  const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated')!);

  if (isAuthenticated) {
    return <Redirect to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
