import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Permissions from '../../hooks/Permissions';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  chip: {
    fontWeight: 300,
  },
  overrides: {
    MuiChip: {
      icon: { color: 'tomato' },
    },
  },
}));

type PlaceEmployeePermissionType = {
  CAN_MANAGE_USER: string;
  CAN_MANAGE_PAYMENT: string;
  CAN_LOGIN_WITH_PASSWORD: string;
  CAN_MANAGE_KEY: string;
  CAN_MANAGE_DEVICE: string;
  CAN_MANAGE_CLIENT: string;
  CAN_CANCEL_TRANSACTION: string;
  CAN_MANAGE_FINANCIAL: string;
  CAN_MANAGE_POST_PRE?: string;
};

const PlaceEmployeePermissions: PlaceEmployeePermissionType = {
  CAN_MANAGE_USER: 'Gerenciar Funcionários',
  CAN_MANAGE_PAYMENT: 'Listar Transações',
  CAN_LOGIN_WITH_PASSWORD: 'Login com Senha App Payface',
  CAN_MANAGE_KEY: 'Gerenciar Chaves de API',
  CAN_MANAGE_DEVICE: 'Gerenciar Dispositivos',
  CAN_MANAGE_CLIENT: 'Listar Clientes',
  CAN_CANCEL_TRANSACTION: 'Realizar Cancelamentos',
  CAN_MANAGE_FINANCIAL: 'Gerenciar Financeiro',
};

if (Permissions.hasFeature('HAS_POST_PAID', Permissions.getPlace())) {
  PlaceEmployeePermissions.CAN_MANAGE_POST_PRE = 'Gerenciar pós/pré-pago';
}

type EmployeeEditableStatesState = {
  permissionsList: any;
  handleClick(_event: any, name: any, value: any): void;
  setPermissions(state: object): void;
  startPermissions(state: object): void;
  allPermissions: any;
};

export function EmployeeEditableStates({
  permissionsList,
  handleClick,
  startPermissions,
  setPermissions,
  allPermissions,
}: EmployeeEditableStatesState) {
  const classes = useStyles();

  const permissionsKeys = Object.keys(PlaceEmployeePermissions);
  const permissionsValues = Object.values(PlaceEmployeePermissions);

  useEffect(() => {
    const newPermissions = { ...allPermissions };

    permissionsList.forEach((permission) => {
      newPermissions[permission] = true;
    });
    setPermissions(newPermissions);
    startPermissions(newPermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsList]);

  return (
    <>
      {permissionsKeys.map((item, index) => {
        const employeePermissionsKeys = Object.keys(allPermissions);
        const employeePermissionsValues = Object.values(allPermissions);
        const foundIndexOf = employeePermissionsKeys.findIndex((permission) => permission === item);
        const value = permissionsValues[foundIndexOf];

        if (employeePermissionsValues[foundIndexOf] === true) {
          return (
            <Chip
              key={index}
              className={classes.chip}
              sx={{ mx: 0.75, my: 0.75 }}
              size="small"
              clickable
              onClick={(event) =>
                handleClick(event, employeePermissionsKeys[foundIndexOf], employeePermissionsValues[foundIndexOf])
              }
              color="primary"
              icon={<HighlightOffIcon />}
              label={value}
            />
          );
        }
        return (
          <Chip
            key={index}
            className={classes.chip}
            sx={{ mx: 0.75, my: 0.75 }}
            size="small"
            clickable
            onClick={(event) =>
              handleClick(event, employeePermissionsKeys[foundIndexOf], employeePermissionsValues[foundIndexOf])
            }
            icon={<AddIcon />}
            label={value}
          />
        );
      })}
    </>
  );
}
