import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';

type GlobalStates = {
  reset: boolean;
  reload: boolean;
};

const initialState: GlobalStates = {
  reset: false,
  reload: false,
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    resetList(state, action) {
      state.reset = action.payload;
    },
    reloadMenu(state, action) {
      state.reload = action.payload;
    },
  },
});

export default slice.reducer;

export function resetDataList(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetList(state));
  };
}

export function reloadSidebar(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.reloadMenu(state));
  };
}
