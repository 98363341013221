import { Box, TableHead, TableCell, TableBody, TableRow, Table, Collapse, Skeleton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

// ----------------------------------------------------------------------

type Status = {
  position: number;
  open: {
    status: boolean;
    reference: number;
  };
};

const columns = [
  { id: 'id', label: 'Id', minWidth: 350, align: 'left' },
  { id: 'typeText', label: 'Cartão', minWidth: 350, align: 'left' },
];

export function CreditsDetails({ position, open: { status, reference } }: Status) {
  const { credit, isRequestingCredit } = useSelector((state: RootState) => state.credits);

  return (
    <>
      {position === reference && (
        <TableRow key={position}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={status} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        {isRequestingCredit ? (
                          <Skeleton animation="wave" variant="rectangular" width={350} height={30} />
                        ) : (
                          credit.id
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {isRequestingCredit ? (
                          <Skeleton animation="wave" variant="rectangular" width={350} height={30} />
                        ) : (
                          credit.typeText
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
