import { Container, Card, Typography } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { EmployeesRegisterForm } from '../_employees/EmployeesRegisterForm';
import { CardsCreateForm } from '../_cards/CardsCreateForm';
import { DevicesQRCodeForm } from '../_devices/DevicesQRCodeForm';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

const formAddress = {
  employees: <EmployeesRegisterForm />,
  cards: <CardsCreateForm />,
  devices: <DevicesQRCodeForm type="create" />,
};

type CreatePageProps = {
  title: string;
  form: string;
};

export function CreatePage({ title, form }: CreatePageProps) {
  return (
    <Page title={title}>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          {title}
        </Typography>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>{formAddress[form]}</Card>
      </Container>
    </Page>
  );
}
