import { useEffect, useState } from 'react';
// material
import { Card, Box, Typography, Skeleton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

// ----------------------------------------------------------------------

type InfoCardProps = {
  title: string;
};

export function CardInfo({ title }: InfoCardProps) {
  const { report, isRequestingReport } = useSelector((state: RootState) => state.cards);
  const [reportMessages, setReportMessages]: any = useState({
    balance: '',
    description: '',
  });

  const renderPostPaidMessage = () => {
    const minSingular = 1;
    const noValue = 0;
    const {
      postPaid: { balance, balanceFormatted, clientCount },
    } = report;
    const message = `Valor a receber de ${clientCount} ${clientCount > minSingular ? 'clientes' : 'cliente'}`;
    if (balance !== noValue) {
      setReportMessages({ balance: balanceFormatted, description: message });
    } else {
      setReportMessages({ balance: balanceFormatted, description: 'Nenhum valor encontrado' });
    }
  };

  const renderLateMessage = () => {
    const minSingular = 1;
    const noValue = 0;
    const {
      late: { balance, balanceFormatted, clientCount, days },
    } = report;
    const message = `Valor a receber de ${clientCount} ${clientCount > minSingular ? 'clientes' : 'cliente'} que não
    ${clientCount > minSingular ? 'adicionam' : 'adiciona'} créditos há mais de ${days} ${
      days > minSingular ? 'dias' : 'dia'
    }`;
    if (balance !== noValue) {
      setReportMessages({ balance: balanceFormatted, description: message });
    } else {
      setReportMessages({ balance: balanceFormatted, description: 'Nenhum valor encontrado' });
    }
  };

  useEffect(() => {
    if (report.id && title === 'Pós-pago') {
      renderPostPaidMessage();
    }
    if (report.id && title === 'Pagamentos em atraso') {
      renderLateMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ flexGrow: 1, minHeight: 250 }}
      >
        <Typography component="div" variant="h6">
          {title}
        </Typography>
        {isRequestingReport ? (
          <Skeleton
            sx={{ display: 'flex', alignSelf: 'center' }}
            animation="wave"
            variant="rectangular"
            width={100}
            height={30}
          />
        ) : (
          <Typography component="div" color={title === 'Pós-pago' ? '#AA00FF' : '#FF6F00'} variant="h3">
            {reportMessages.balance}
          </Typography>
        )}
        {isRequestingReport ? (
          <Skeleton
            sx={{ display: 'flex', alignSelf: 'center', mb: '20px' }}
            animation="wave"
            variant="rectangular"
            width={100}
            height={30}
          />
        ) : (
          <Typography sx={{ mb: '20px' }} component="div" variant="caption" color="textSecondary">
            {reportMessages.description}
          </Typography>
        )}
      </Box>
    </Card>
  );
}
