import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../utils/axios';
import { Detail, IdentityResponse } from '../../@types/identityVerifications';
import { store } from '../store';

type IdentityState = {
  isLoading: boolean;
  items: [];
  detail: Detail | null;
};

const initialState: IdentityState = {
  isLoading: false,
  items: [],
  detail: null,
};

const slice = createSlice({
  name: 'identityVerifications',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getIdentitiesSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    getIdentitySuccess(state, action) {
      state.isLoading = false;
      state.detail = action.payload;
    },
  },
});

export default slice.reducer;

export function getIdentities() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());

    try {
      const { data } = await api.get('/face-usage-intentions');
      dispatch(slice.actions.getIdentitiesSuccess(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      throw new Error(error.message);
    }
  };
}

export function getIdentity(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await api.get<IdentityResponse>(`/face-usage-intentions/${id}`);

      if (data) {
        const detail: Detail = {
          id: data.id,
          name: `${data.matchedUser?.firstName} ${data.matchedUser?.lastName}`,
          phone: data.matchedUser?.phone?.number,
          picture: data?.frames[0]?.url,
          createdAt: new Date(data.matchedUser?.createdAt).toLocaleDateString('pt-BR'),
          status: data.outcome,
        };
        dispatch(slice.actions.getIdentitySuccess(detail));
      }
    } catch (error) {
      throw Error(error.message);
    }
  };
}
