import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card, CardContent, Container, Tab, Tabs, Paper, Box, Button } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BlockIcon from '@material-ui/icons/Block';
import { getCardsByPlace, resetPaginationData, getUserById, getCardById } from '../../redux/slices/cards';
import { getClientById } from '../../redux/slices/clients';
import { ClientsPanelCredits } from '../../components/_clients/ClientsPanelCredits';
import { PATH_DASHBOARD } from '../../routes/paths';
import { TransactionsDialogToCancel } from '../../components/_transactions/TransactionsDialogToCancel';
import { RootState } from '../../redux/store';
import { ClientsPanelProfile } from '../../components/_clients/ClientsPanelProfile';
import { ClientsPanelCards } from '../../components/_clients/ClientsPanelCards';
import { ActionDialog } from '../../components/library/ActionDialog';
import { InputDialog } from '../../components/library/InputDialog';
import { ClientsPanelTransactions } from '../../components/_clients/ClientsPanelTransactions';
import Permission from '../../hooks/Permissions';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  formButton: {
    marginRight: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

type ParamTypes = {
  id: string;
};

export default function ClientsDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstPosition = 0;
  const sort = 'createdAt';
  const { id } = useParams<ParamTypes>();
  const classes = useStyles();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog]: any = useState({
    status: false,
  });
  const [openBlockDialog, setOpenBlockDialog]: any = useState({
    status: false,
  });
  const { reset } = useSelector((state: RootState) => state.global);
  const { _end, _order, _start, resetPagination, cards, isRequestingCard, isRequestingUser } = useSelector(
    (state: RootState) => state.cards
  );
  const [value, setValue] = useState('1');

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleBackPage = () => {
    const previousPage = JSON.parse(sessionStorage.getItem('previous')!);
    switch (previousPage) {
      case 'cards':
        history.push(PATH_DASHBOARD.cards.root);
        break;
      case 'identification':
        history.push(PATH_DASHBOARD.identifications.root);
        break;
      default:
        history.push(PATH_DASHBOARD.clients.root);
    }
  };

  const handleAddCredits = () => {
    setOpenAddDialog({ ...openAddDialog, status: !openAddDialog.status });
  };

  const handleBlockCard = () => {
    setOpenBlockDialog({ ...openBlockDialog, status: !openBlockDialog.status });
  };

  useEffect(() => {
    if (resetPagination === true) {
      void dispatch(getCardsByPlace({ _end, _order, _sort: sort, _start, q: '', late: false, user: id }));
      void dispatch(resetPaginationData(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPagination]);

  useEffect(() => {
    if (reset === true) {
      history.push(`${PATH_DASHBOARD.clients.root}/${id}`);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (cards?.length && cards[firstPosition] && cards[firstPosition].userId === id) {
      localStorage.setItem('user', JSON.stringify(id));
      void dispatch(getUserById(cards[firstPosition].userId));
      void dispatch(getCardById(cards[firstPosition].id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  useEffect(() => {
    void dispatch(getClientById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void dispatch(getCardsByPlace({ _end, _order, _sort: sort, _start, q: '', late: false, user: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="CRM > Detalhamento do Cliente">
      <TransactionsDialogToCancel openDialog={openCancelDialog} setOpenDialog={setOpenCancelDialog} />
      <ActionDialog
        openDialog={openBlockDialog}
        setOpenDialog={setOpenBlockDialog}
        type={cards[firstPosition] && cards[firstPosition].blocked ? 'unlockCard' : 'blockCard'}
      />
      <InputDialog openDialog={openAddDialog} setOpenDialog={setOpenAddDialog} type="addCredit" />
      <Container maxWidth="xl">
        <Paper className={classes.root}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                <Tab sx={{ ml: 5 }} label="Perfil" value="1" />
                {Permission.hasFeature('HAS_POST_PAID') && <Tab label="Cartões" value="2" />}
                {Permission.hasFeature('HAS_POST_PAID') && <Tab label="Créditos" value="3" />}
                <Tab label="Pagamentos" value="4" />
              </Tabs>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: 2,
              }}
            >
              <Button
                type="button"
                color="primary"
                variant="outlined"
                size="small"
                startIcon={<ArrowBackIcon />}
                className={classes.formButton}
                onClick={handleBackPage}
              >
                Voltar
              </Button>
              {!!cards.length && !!cards[firstPosition] && Permission.hasFeature('HAS_POST_PAID') && (
                <Button
                  className={classes.formButton}
                  type="button"
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<AddCircleIcon />}
                  onClick={handleAddCredits}
                >
                  Adicionar crédito
                </Button>
              )}
              {!!cards.length && !!cards[firstPosition] && Permission.hasFeature('HAS_POST_PAID') && (
                <Button
                  className={classes.formButton}
                  type="button"
                  color="error"
                  variant="contained"
                  size="small"
                  startIcon={<BlockIcon />}
                  onClick={handleBlockCard}
                >
                  {cards[firstPosition].blocked === false ? 'Bloquear cartão' : 'Desbloquear cartão'}
                </Button>
              )}
            </Box>
          </Box>
        </Paper>
        <TabPanel value={value} index="1">
          <ClientsPanelProfile />
        </TabPanel>
        <TabPanel value={value} index="2">
          {!isRequestingUser &&
          !isRequestingCard &&
          !!cards.length &&
          !!cards[firstPosition] &&
          Permission.hasFeature('HAS_POST_PAID') ? (
            <ClientsPanelCards />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="body1">Este cliente ainda não possui cartões</Typography>
              </CardContent>
            </Card>
          )}
        </TabPanel>
        <TabPanel value={value} index="3">
          {!isRequestingCard && !!cards.length && !!cards[firstPosition] && Permission.hasFeature('HAS_POST_PAID') ? (
            <ClientsPanelCredits />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="body1">Este cliente ainda não possui créditos</Typography>
              </CardContent>
            </Card>
          )}
        </TabPanel>
        <TabPanel value={value} index="4">
          <ClientsPanelTransactions />
        </TabPanel>
      </Container>
    </Page>
  );
}
