import { useEffect, useState, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Skeleton } from '@material-ui/core';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { getUserById } from '../../redux/slices/cards';
import Permission from '../../hooks/Permissions';

const DEFAULT_AVATAR = '/static/brand/logo_single_avatar.svg';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[600],
  },
  picture: {
    border: '5px solid grey',
    borderRadius: '50%',
  },
}));

type ParamTypes = {
  id: string;
};

export function ClientsPanelProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();
  const { client, isLoading } = useSelector((state: RootState) => state.clients);
  const { cards } = useSelector((state: RootState) => state.cards);
  const [avatar, setAvatar] = useState<string>();

  const [clientInfo, setClientInfo] = useState<
    | {
        name: string | ReactElement<any, any>;
        date: string | ReactElement<any, any>;
        avatar: string | ReactElement<any, any>;
      }
    | undefined
  >();

  useEffect(() => {
    let avatarUrl: string = DEFAULT_AVATAR;
    if (Permission.hasFeature('HAS_POST_PAID')) {
      if (cards.length > 0 && !!cards[0].avatarUrl && client.id === id) {
        avatarUrl = cards[0].avatarUrl as string;
      }
    }
    setAvatar(avatarUrl);
  }, [cards, client.id, clientInfo, id]);

  useEffect(() => {
    setClientInfo(client);
  }, [client]);

  useEffect(() => {
    if (client?.id && client.id === id && !isLoading) {
      const formattedDate = moment(client.createdAt).format('DD/MM/YYYY HH:mm:ss');
      const formattedClient = `${client.firstName} ${client.lastName}`;

      setClientInfo({
        date: formattedDate,
        name: formattedClient,
        avatar: client.avatarUrl,
      });
    } else {
      setClientInfo({
        date: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        name: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        avatar: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (id) {
      void dispatch(getUserById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={0} sx={{ mt: 1, mb: 3, mx: 0 }}>
          <Grid container item alignSelf="center" justifyContent="center" xs={12} md={4} sx={{ pb: 2 }}>
            {isLoading ? (
              <Skeleton animation="wave" variant="circular" width={100} height={100} />
            ) : (
              <img
                className={classes.picture}
                src={avatar}
                alt="profile img"
                width="240px"
                height="240px"
                style={{ objectFit: 'cover' }}
              />
            )}
          </Grid>
          <Grid container item xs={12} md={8} sx={{ pl: 4 }}>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Nome
              </Typography>
              <Typography variant="body1">{clientInfo?.name}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption" className={classes.label}>
                Cliente desde
              </Typography>
              <Typography variant="body1">{clientInfo?.date}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
