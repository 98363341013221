import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import roundHelp from '@iconify/icons-ic/round-help';
import { Container, Grid, Box, Card, Typography, Tooltip, IconButton } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { RootState } from '../../redux/store';
import { IdentificationChart, IdentificationsDataList, IdentificationsFilter } from '../../components/_identifications';
import { getIdentificationSuccessReport } from '../../redux/slices/identifications';

const { Page } = ComponentsLib.Generics;

const useStyles = makeStyles((theme) => ({
  formWarning: {
    marginLeft: theme.spacing(3),
    marginTop: '-1em',
    marginBottom: '1em',
    fontSize: '0.85em',
    color: '#FF4842',
    minWidth: 120,
  },
}));

export default function Identifications() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { identificationsReport, period, filterSubmit } = useSelector((state: RootState) => state.identifications);
  const [dataReport, setDataReport] = useState([]);

  useEffect(() => {
    dispatch(
      getIdentificationSuccessReport({
        period,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterSubmit) {
      dispatch(
        getIdentificationSuccessReport({
          period,
        })
      );
    }
  }, [dispatch, filterSubmit, period]);

  useEffect(() => {
    if (identificationsReport?.identificationsReport?.length > 0) {
      getValueTotalSuccess(identificationsReport);
    }
  }, [identificationsReport]);

  const getValueTotalSuccess = (identification): string => {
    const dataIdentificationReport = identification.identificationsReport;

    dataIdentificationReport.map((item) => {
      const { status, total } = item;
      if (status === 'SUCCESS') {
        return setDataReport(total);
      }
      return null;
    });
    return '';
  };

  return (
    <Page title="CRM > Identificações">
      <Container maxWidth="xl">
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                sx={{ flexGrow: 1, minHeight: 383 }}
              >
                <Box display="flex" flexDirection="column">
                  <Box
                    sx={{ height: 10, mt: 1, mb: 1 }}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6">Identificações</Typography>
                    <Tooltip title="Identificações realizadas com sucesso" placement="right" arrow>
                      <IconButton sx={{ color: '#919EAB' }}>
                        <Icon style={{ margin: 2 }} icon={roundHelp} width="20" height="20" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography color="primary" component="span" variant="h3">
                    {dataReport}
                  </Typography>
                  <Typography sx={{ mt: 1 }} color="textSecondary" component="span" variant="caption">
                    Realizadas com sucesso
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <IdentificationChart />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <IdentificationsFilter />
              <div className={classes.formWarning}>
                Atenção! As informações de data são baseadas no Fuso horário de Brasília, DF (GMT-3).
              </div>
              <IdentificationsDataList />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
