import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { deleteCredit, addCredit } from '../../utils/creditsServices';
import { resetDataList } from '../../redux/slices/global';
import { RootState } from '../../redux/store';
import CurrencyInput from './CurrencyInput';

type DialogStates = {
  openDialog: any;
  setOpenDialog(state: boolean): void;
  type: string;
};

const messageOptions = {
  cancelTransaction: [
    'Cancelar transação',
    'O cancelamento não poderá ser desfeito. Digite a sua senha Payface de 4 dígitos para continuar',
  ],
  cancelCredit: [
    'Cancelar crédito',
    'O cancelamento não poderá ser desfeito. Digite a sua senha Payface de 4 dígitos para continuar',
  ],
  addCredit: ['Adicionar crédito', 'Insira o valor de crédito a ser adicionado para o cliente'],
};

const titleIndex = 0;
const descriptionIndex = 1;

export function InputDialog({ openDialog, setOpenDialog, type }: DialogStates) {
  const dispatch = useDispatch();
  const noValue = 0;
  const { isRequestingTransactions } = useSelector((state: RootState) => state.transactions);
  const { isRequestingCredits } = useSelector((state: RootState) => state.credits);
  const { card } = useSelector((state: RootState) => state.cards);
  const [creditValue, setCreditValue] = useState(noValue);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [requestingSave, setRequestingSave] = useState(false);
  const [payOff, setPayOff]: any = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog({ ...openDialog, status: false });
  };

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value: any) => {
    if (!value) {
      setPasswordError('Sua senha deve conter 4 dígitos');
    } else {
      setPasswordError('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    validatePassword(value);
  };

  const handleSave = async () => {
    setRequestingSave(true);
    try {
      const response = await deleteCredit({ id: openDialog.id, password });
      if (response) {
        setOpenDialog({ ...openDialog, status: false });
        setRequestingSave(false);
        enqueueSnackbar('Crédito excluído com sucesso', { variant: 'success' });
        void dispatch(resetDataList(true));
      }
    } catch (error) {
      setRequestingSave(false);
      enqueueSnackbar('Não foi possível excluir o crédito', { variant: 'error' });
    }
  };

  const handleAddCredit = async () => {
    setRequestingSave(true);
    try {
      const convertToPositiveFactor = -1;
      const userId = JSON.parse(localStorage.getItem('user')!);
      const creditToAdd = payOff ? card.balance * convertToPositiveFactor : creditValue;
      const response = await addCredit({ amount: creditToAdd, cardId: card.id, userId }); // props
      if (response) {
        setTimeout(() => {
          setOpenDialog({ ...openDialog, status: false });
        }, 5000);
        setRequestingSave(false);
        enqueueSnackbar('Crédito adicionado com sucesso', { variant: 'success' });
        void dispatch(resetDataList(true));
      }
    } catch (error) {
      setRequestingSave(false);
      enqueueSnackbar('Não foi possível adicionar o crédito', { variant: 'error' });
    }
  };

  const handleCreditValue = (event) => {
    const creditAmount = event.target.value;
    const removeDots = creditAmount.replace(/\./g, '');
    const changeCommaForDot = removeDots.replace(/,/g, '.');
    const cleanString = changeCommaForDot.replace(/[|&;$%@"<>()+R ]/g, '');
    setCreditValue(Number(cleanString));
  };

  const handlePayOffOutstandingBalance = () => {
    const positiveOrNegative = Math.sign(card.balance);
    const negativeSign = -1;
    if (positiveOrNegative === negativeSign) {
      const balanceValue = card.balanceFormatted.replace(/-/g, '');
      return (
        <FormControlLabel
          control={<Checkbox checked={payOff} onChange={() => setPayOff(!payOff)} name="checkedA" />}
          label={`Quitar saldo devedor de ${balanceValue}`}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (isRequestingTransactions === false || isRequestingCredits === false) {
      setRequestingSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingTransactions, isRequestingCredits]);

  return (
    <Dialog open={openDialog.status} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Box>
        <DialogTitle id="form-dialog-title">{messageOptions[type][titleIndex]}</DialogTitle>
        <DialogContent sx={{ pb: 0, minHeight: '191px', maxWidth: '374px' }}>
          <DialogContentText sx={{ mb: 4 }}>{messageOptions[type][descriptionIndex]}</DialogContentText>
          {type !== 'addCredit' ? (
            <TextField
              required={true}
              name="password"
              id="password"
              label="Sua senha Payface ツ"
              variant="outlined"
              error={!!passwordError}
              helperText={passwordError}
              type={showPassword ? 'number' : 'password'}
              onChange={(event) => handleChange(event)}
              inputProps={{
                maxLength: 4,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickPassword}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Box display="flex" flexDirection="column">
              <CurrencyInput handleCredit={handleCreditValue} disableText={payOff} />
              {handlePayOffOutstandingBalance()}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          {type === 'addCredit' ? (
            <LoadingButton variant="contained" onClick={handleAddCredit} color="primary" pending={requestingSave}>
              Confirmar
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              disabled={password.length < 4}
              onClick={handleSave}
              color="primary"
              pending={requestingSave}
            >
              Confirmar
            </LoadingButton>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
}
