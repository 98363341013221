import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  IconButton,
  Box,
  Chip,
  Tooltip,
  Skeleton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  updateDirectionOption,
  getEmployeesByPlace,
  disablingToggleOnEditState,
  resetEmployeesStates,
  updateSortOption,
} from '../../redux/slices/employees';
import { RootState } from '../../redux/store';
import { EmployeesDetails } from './EmployeesDetails';
import { EmployeeEditableStates } from './EmployeeEditableStates';
import { DialogToSave } from './DialogToSave';
import { DialogToDisable } from './DialogToDisable';
import Permissions from '../../hooks/Permissions';

// ----------------------------------------------------------------------

const columns = [
  { id: 'expand', field: 'expand', label: '', width: 20, align: 'left' },
  { id: 'user', field: 'firstName', label: 'Usuário', width: 200, align: 'left', sortable: true },
  { id: 'permissions', field: 'permissions', label: 'Permissões', width: 200, align: 'left' },
  { id: 'createdAt', field: 'createdAt', label: 'Criado em', width: 150, align: 'center', sortable: true },
  { id: 'updatedAt', field: 'updatedAt', label: 'Editado em', width: 150, align: 'center', sortable: true },
  { id: 'manager', field: 'actions', label: 'Ações', width: 150, align: 'center' },
];

const useStyles = makeStyles(() => ({
  chip: {
    fontWeight: 300,
    paddingRight: '4px',
  },
}));

type EmployeePermissions = {
  CAN_MANAGE_USER: string | boolean;
  CAN_MANAGE_PAYMENT: string | boolean;
  CAN_LOGIN_WITH_PASSWORD: string | boolean;
  CAN_MANAGE_KEY: string | boolean;
  CAN_MANAGE_DEVICE: string | boolean;
  CAN_MANAGE_CLIENT: string | boolean;
  CAN_CANCEL_TRANSACTION: string | boolean;
  CAN_MANAGE_FINANCIAL: string | boolean;
  CAN_MANAGE_POST_PRE?: string | boolean;
};

const PlaceEmployeePermissions: EmployeePermissions = {
  CAN_MANAGE_USER: 'Gerenciar Funcionários',
  CAN_MANAGE_PAYMENT: 'Listar Transações',
  CAN_LOGIN_WITH_PASSWORD: 'Login com Senha App Payface',
  CAN_MANAGE_KEY: 'Gerenciar Chaves de API',
  CAN_MANAGE_DEVICE: 'Gerenciar Dispositivos',
  CAN_MANAGE_CLIENT: 'Listar Clientes',
  CAN_CANCEL_TRANSACTION: 'Realizar Cancelamentos',
  CAN_MANAGE_FINANCIAL: 'Gerenciar Financeiro',
};

const initialPermissionsStates: EmployeePermissions = {
  CAN_MANAGE_USER: false,
  CAN_MANAGE_PAYMENT: false,
  CAN_LOGIN_WITH_PASSWORD: false,
  CAN_MANAGE_KEY: false,
  CAN_MANAGE_DEVICE: false,
  CAN_MANAGE_CLIENT: false,
  CAN_CANCEL_TRANSACTION: false,
  CAN_MANAGE_FINANCIAL: false,
};

if (Permissions.hasFeature('HAS_POST_PAID', Permissions.getPlace())) {
  PlaceEmployeePermissions.CAN_MANAGE_POST_PRE = 'Gerenciar pós/pré-pago';
  initialPermissionsStates.CAN_MANAGE_POST_PRE = false;
}

export function EmployeesDataList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [5, 10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);
  const [open, setOpen]: any = useState({
    status: false,
    reference: '',
  });
  const [editMode, setEditMode] = useState({
    status: false,
    reference: '',
  });
  const [openSaveDialog, setOpenSaveDialog] = useState({
    status: false,
    original: [],
  });
  const [openDisableDialog, setOpenDisableDialog] = useState({
    status: false,
    original: [],
  });
  const [employeePermissions, setEmployeePermissions]: any = useState(initialPermissionsStates);
  const [startPermissions, setStartPermissions]: any = useState(initialPermissionsStates);
  const { _order, employees, headerCount, deleted, resetPagination, isRequestingEmployees, _sort } = useSelector(
    (state: RootState) => state.employees
  );

  const [sort, setSort] = useState(_sort);
  const minArrayLength = 1;

  const handlePermissionsClick = (_event, name, value) => {
    setEmployeePermissions({ ...employeePermissions, [name]: !value });
  };

  const renderPermissions = (permissions, index) => {
    const permissionsKeys = Object.keys(PlaceEmployeePermissions);
    const permissionsValues = Object.values(PlaceEmployeePermissions);
    const friendlyNamePermissionsMapped = permissions.map((item) => {
      const foundIndexOf = permissionsKeys.findIndex((permission) => permission === item);
      return permissionsValues[foundIndexOf];
    });

    if (editMode.status === true && index === editMode.reference) {
      return (
        <EmployeeEditableStates
          permissionsList={permissions}
          handleClick={handlePermissionsClick}
          setPermissions={setEmployeePermissions}
          startPermissions={setStartPermissions}
          allPermissions={employeePermissions}
        />
      );
    }

    return friendlyNamePermissionsMapped.map(
      (item, index) =>
        item && (
          <Chip
            className={classes.chip}
            sx={{ mx: 0.75, my: 0.75 }}
            key={index}
            size="small"
            icon={<CheckIcon />}
            label={item}
          />
        )
    );
  };

  const handlePopover = (_event, index) => {
    setOpen({ ...open, status: !open.status, reference: index });
  };

  const handleEditPermissions = (_event, index, row) => {
    if (editMode.status === true) {
      setOpenSaveDialog({ ...openSaveDialog, status: true, original: row });
    } else {
      setEditMode({ ...editMode, status: true, reference: index });
      dispatch(disablingToggleOnEditState(editMode));
    }
  };

  const cancelEditPermissions = (_event) => {
    setEmployeePermissions(initialPermissionsStates);
    setEditMode({ ...editMode, status: false, reference: '' });
    dispatch(disablingToggleOnEditState(!editMode));
  };

  const handleDisableEmployee = (_event, _index, row) => {
    setOpenDisableDialog({ ...openDisableDialog, status: !openDisableDialog.status, original: row });
  };

  const renderManagementOptions = (index, row) =>
    editMode.status === true && index === editMode.reference ? (
      <Box component="div" display="block">
        <Tooltip title="Cancelar">
          <IconButton aria-label="clear" onClick={(event) => cancelEditPermissions(event)}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Salvar">
          <IconButton aria-label="save" onClick={(event) => handleEditPermissions(event, index, row)}>
            <SaveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    ) : (
      <Box component="div" display="block">
        <Tooltip title="Editar">
          <IconButton
            disabled={editMode.status === true}
            aria-label="edit"
            onClick={(event) => handleEditPermissions(event, index, row)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={row.deleted === false ? 'Desativar' : 'Ativar'}>
          <IconButton
            disabled={editMode.status === true}
            aria-label="disable"
            onClick={(event) => handleDisableEmployee(event, index, row)}
          >
            {row.deleted === true ? <BlockIcon color="error" fontSize="small" /> : <BlockIcon fontSize="small" />}
          </IconButton>
        </Tooltip>
      </Box>
    );

  const renderExpand = (index: number) => (
    <Box component="div" display="flex" alignItems="center">
      <IconButton aria-label="expand" onClick={(event) => handlePopover(event, index)}>
        {open.status === true && index === open.reference ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );

  const employeesData = isRequestingEmployees
    ? [...Array(initialRows)].map(() => ({
        expand: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
        user: <Skeleton animation="wave" variant="rectangular" width={180} height={30} />,
        permissions: <Skeleton animation="wave" variant="rectangular" width={300} height={30} />,
        created: <Skeleton animation="wave" variant="rectangular" width={130} height={30} />,
        updated: <Skeleton animation="wave" variant="rectangular" width={130} height={30} />,
        manager: <Skeleton animation="wave" variant="rectangular" width={130} height={30} />,
      }))
    : employees.map((row, index) => {
        const created = moment(row.createdAt).format('DD/MM/YYYY');
        const updated = moment(row.updatedAt).format('DD/MM/YYYY');
        const user = `${row.firstName} ${row.lastName}`;
        return {
          expand: renderExpand(index),
          id: row.id,
          cpf: row.cpf,
          status: row.deleted,
          deleted: row.deletedAt,
          user,
          permissions: renderPermissions(row.permissions, index),
          createdAt: created,
          updatedAt: updated,
          manager: renderManagementOptions(index, row),
          original: row,
        };
      });

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = (field: string) => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
    dispatch(updateSortOption(field));
    setSort(field);
  };

  useEffect(() => {
    if (resetPagination === true || deleted === true) {
      setPage(initialPage);
      setRowsPerPage(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPagination, deleted]);

  useEffect(() => {
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(getEmployeesByPlace({ _end: end, _order, _sort: sort, _start: start, deleted }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sort]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => void dispatch(resetEmployeesStates()), []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <DialogToSave
        openDialog={openSaveDialog}
        setOpenDialog={setOpenSaveDialog}
        permissions={employeePermissions}
        startPermissions={startPermissions}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <DialogToDisable openDialog={openDisableDialog} setOpenDialog={setOpenDisableDialog} />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {employeesData.length < minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.sortable ? (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                    <TableSortLabel
                      active={sort === column.field}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={() => handleDirection(column.field)}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ minWidth: column.width }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {employeesData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return <TableCell key={column.id}>{value}</TableCell>;
                  })}
                </TableRow>
                <EmployeesDetails data={row} position={index} open={open} />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
