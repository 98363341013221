import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { updatePermissionsEmployee } from '../../utils/employeeServices';
import { resetUpdatedPermissions, disablingToggleOnEditState } from '../../redux/slices/employees';
import { RootState } from '../../redux/store';
import { Messages } from '../../@types/messages';

type DialogToSaveStates = {
  openDialog: any;
  setOpenDialog(state: object): void;
  editMode: any;
  setEditMode(state: object): void;
  permissions: any;
  startPermissions: any;
};

export function DialogToSave({
  openDialog,
  setOpenDialog,
  permissions,
  startPermissions,
  editMode,
  setEditMode,
}: DialogToSaveStates) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [requestingEditPermissions, setRequestingEditPermissions] = useState(false);
  const { isRequestingEmployees } = useSelector((state: RootState) => state.employees);
  const { status, original } = openDialog;

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog({ ...openDialog, status: false });
  };

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value: any) => {
    if (!value) {
      setPasswordError('Senha inválida');
    } else {
      setPasswordError('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    validatePassword(value);
  };

  const handleSave = async () => {
    setRequestingEditPermissions(true);
    try {
      await updatePermissionsEmployee({ password, original, permissions });
      setOpenDialog({ ...openDialog, status: false });
      setEditMode({ ...editMode, status: false, referece: '' });
      void dispatch(disablingToggleOnEditState(false));
      void dispatch(resetUpdatedPermissions(true));
      enqueueSnackbar(Messages.PERMISSIONS_UPDATE_SUCCESS, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (error: any) {
      setRequestingEditPermissions(false);
      enqueueSnackbar(Messages[error.message], {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
    setRequestingEditPermissions(false);
  };

  useEffect(() => {
    const stringPermissions = JSON.stringify(permissions);
    const stringStartPermissions = JSON.stringify(startPermissions);
    if (stringPermissions === stringStartPermissions) {
      setDialogMessage('Nenhuma alteração encontrada');
    } else {
      setDialogMessage(`Gostaria de confirmar as permissões para ${original?.firstName} ${original?.lastName}?`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, original]);

  useEffect(() => {
    if (isRequestingEmployees === false) {
      setRequestingEditPermissions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingEmployees]);

  return (
    <Dialog open={status} onClose={handleClose} aria-labelledby="form-dialog-title">
      {dialogMessage && dialogMessage === 'Nenhuma alteração encontrada' ? (
        <Box>
          <DialogContent sx={{ pb: 0, maxWidth: '374px' }}>
            <DialogContentText>{dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Box>
      ) : (
        <Box>
          <DialogTitle id="form-dialog-title">Salvar alterações</DialogTitle>
          <DialogContent sx={{ pb: 0, minHeight: '191px', maxWidth: '374px' }}>
            <DialogContentText sx={{ mb: 4 }}>{dialogMessage}</DialogContentText>
            <TextField
              required={true}
              name="password"
              id="password"
              label="Sua senha Payface ツ"
              variant="outlined"
              error={!!passwordError}
              helperText={passwordError}
              type={showPassword ? 'number' : 'password'}
              onChange={(event) => handleChange(event)}
              inputProps={{
                maxLength: 4,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickPassword}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions sx={{ pt: 0 }}>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              disabled={password.length < 4}
              onClick={handleSave}
              color="primary"
              pending={requestingEditPermissions}
            >
              Confirmar
            </LoadingButton>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
}
