export enum Messages {
  //
  // GENERAL
  //
  NO_DATA_FOUND = 'Nenhum dado encontrado.',
  //
  // AUTH
  //
  INVALID_USER_PIN = 'Senha inválida. Informe sua senha corretamente.',
  INVALID_USER_PIN_FORMAT = 'O formato da senha está incorreto.',
  INVALID_USER_PIN_TOO_EASY = 'A senha digitada é muito fácil.',
  PASSWORD_FORMAT_INFO = 'Digite sua senha de 4 dígitos.',
  TOO_MANY_REQUESTS = 'Você fez muitas requisições. Aguarde alguns minuto e tente novamente.',
  USER_NOT_FOUND = 'Usuário não encontrado. Verifique se o CPF está correto. O funcionário deverá ser previamente cadastrado pelo app de usuário.',
  //
  // EMPLOYEES
  //
  EMPLOYER_CREATED = 'Funcionário cadastrado com sucesso.',
  NO_PERMISSIONS_SELECTED_INFO = 'Nenhuma permissão selecionada.',
  PERMISSIONS_UPDATE_SUCCESS = 'Permissões editadas com sucesso.',
  PERMISSIONS_UPDATE_ERROR = 'Não foi possível editar as permissões.',
  EMPLOYER_FILE_NAME = 'exportacao_funcionarios.',
  EMPLPLOYER_DEACTIVATE_SUCCESS = 'Funcionário desativado com sucesso.',
  EMPLPLOYER_DEACTIVATE_ERROR = 'Não foi possível desativar o funcionário.',
  EMPLPLOYER_ACTIVATE_SUCCESS = 'Funcionário ativado com sucesso.',
  EMPLPLOYER_ACTIVATE_ERROR = 'Não foi possível ativar o funcionário.',
  USER_ALREADY_SET = 'Funcionário já cadastrado.',
  //
  // TRANSACTIONS
  //
  TRANSACTION_CANCEL_ERROR = 'Não foi possível cancelar a transação.',
  TRANSACTION_CANCEL_SUCCESS = 'Transação cancelada com sucesso.',
  CRM_TRANSACTION_TOO_OLD = 'Você não pode cancelar uma transação muito antiga.',
  TRANSACTION_FILE_NAME = 'exportacao_transacoes',
  //
  // CARDS
  //
  NO_CARDS_FOUND = 'Nenhuma cartão encontrado.',
}

export type MessageString = keyof typeof Messages;
