import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  chip: {
    fontWeight: 300,
    paddingRight: '4px',
    paddingLeft: '4px',
    color: '#fff',
  },
  deletedChip: {
    backgroundColor: 'red',
  },
  blockedChip: {
    backgroundColor: '#FF4842',
  },
  lateChip: {
    backgroundColor: '#FF6F00',
  },
  readyChip: {
    backgroundColor: '#4caf50',
  },
}));

type CardsStatusProps = {
  deleted: boolean;
  blocked: boolean;
  late: boolean;
};

export function CardsStatus({ deleted, blocked, late }: CardsStatusProps) {
  const classes = useStyles();

  const renderLabel = () => {
    if (deleted) return `Cartão removido definitivamente${late ? ' e pagamento atrasado' : ''}`;

    if (blocked) return `Cartão bloqueado${late ? ' e pagamento atrasado' : ''}`;

    if (late) return 'Pagamento atrasado';

    return 'Pronto para uso';
  };

  return (
    <Chip
      className={`${classes.chip} ${
        deleted ? classes.deletedChip : blocked ? classes.blockedChip : late ? classes.lateChip : classes.readyChip
      }`}
      sx={{ mx: 0.75, my: 0.75 }}
      size="small"
      label={renderLabel()}
    />
  );
}
