import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Drawer, Hidden, Typography, ListSubheader } from '@material-ui/core';
import { UtilsLib } from '@payface-frontends-libs/utils';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { useDispatch, useSelector } from 'react-redux';
import { SelectPlaceId } from './SelectPlaceId';
import { getProfile } from '../../redux/slices/transactions';
import { RootState } from '../../redux/store';
import { UserProfile } from '../../@types/user';

const {
  Generics: { Logo, MyAvatar, Scrollbar },
} = ComponentsLib;

const { environmentConfig } = UtilsLib.Config;
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
  dashboardPath: {
    general: {
      app: string;
    };
  };
  dashboardList: any;
};

export function DashboardSidebar({ isOpenSidebar, onCloseSidebar, dashboardPath, dashboardList }: NavBarProps) {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.transactions);
  const { pathname } = useLocation();
  const [storageName, setStorageName] = useState('');
  const userData = profile as UserProfile;

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const userName = userData.firstName;
    setStorageName(userName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to={dashboardPath.general.app}>
          <Logo />
        </RouterLink>
      </Box>

      <AccountStyle>
        <MyAvatar />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {storageName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {environmentConfig.stage}
          </Typography>
        </Box>
      </AccountStyle>

      <ListSubheader
        disableSticky
        disableGutters
        sx={{
          pl: 5,
          color: 'text.primary',
          typography: 'overline',
        }}
      >
        estabelecimento
      </ListSubheader>
      <Box sx={{ px: 2.5 }}>
        <SelectPlaceId />
      </Box>
      {dashboardList}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
