import { useState } from 'react';
import QrReader from 'react-qr-reader';
import { Button, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import { Icon } from '@iconify/react';
import roundClose from '@iconify/icons-ic/round-close';

type QRCodeFieldProps = {
  setParams(params): void;
  params: any;
};

export function QRCodeField({ setParams, params }: QRCodeFieldProps) {
  const [codeState, setCodeState] = useState({
    error: '',
    open: false,
  });

  const handleScan = (data) => {
    if (data && data !== params.uuid) {
      setParams({ ...params, uuid: data });
    }
  };

  const handleError = (errorMessage: any) => {
    setCodeState({ ...codeState, error: `${errorMessage}` });
  };

  const handleButtonClick = () => {
    setCodeState({ ...codeState, open: !codeState.open });
  };

  return (
    <>
      {codeState.error === 'NotAllowedError: Permission denied' ? (
        <Button
          sx={{
            mt: 4,
            mb: 3,
          }}
          onClick={handleButtonClick}
          variant="contained"
          color="error"
        >
          <Icon icon={roundClose} width={24} height={24} />
          {codeState.open ? 'Fechar QRCode' : 'Abrir QRCode'}
        </Button>
      ) : (
        <Button
          sx={{
            mt: 4,
            mb: 3,
          }}
          onClick={handleButtonClick}
          variant="contained"
        >
          <CameraIcon />
          {codeState.open ? 'Fechar QRCode' : 'Abrir QRCode'}
        </Button>
      )}
      {codeState.open && (
        <>
          {codeState.error && (
            <Typography sx={{ mb: 3, color: '#E57373' }}>
              {/* // @todo use code from response and set error message based on constants  */}
              {codeState.error === 'NotAllowedError: Permission denied'
                ? 'Permissão de acesso a câmera negada. Altere essa permissão nas configurações do seu navegador'
                : ''}
            </Typography>
          )}
          <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '300px' }} />
        </>
      )}
    </>
  );
}
