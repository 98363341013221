import { v4 as uuidv4 } from 'uuid';

export const url = process.env.REACT_APP_CRM_API_URL || 'http://127.0.0.1:3000/crm';

export const STAGE = process.env.REACT_APP_STAGE || 'development';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';

export const FEATURE_SANDBOX =
  process.env.REACT_APP_FEATURE_SANDBOX === undefined
    ? STAGE === 'development' || STAGE === 'sandbox'
    : process.env.REACT_APP_FEATURE_SANDBOX === 'true';

export const LOCALSTORAGE_JWT_TOKEN_NAME = 'crm-token';
export const LOCALSTORAGE_DEVICE_UUID_NAME = 'crm-device-uuid';
export const LOCALSTORAGE_SELECTED_PLACE_ID = 'crm-selected-place-id';

export const FILTER_PERIOD_NAME = 'crm-filters-period';

// eslint-disable-next-line import/no-mutable-exports
export let deviceUUID = localStorage.getItem(LOCALSTORAGE_DEVICE_UUID_NAME);

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

export function loadConfig() {
  if (!deviceUUID) {
    deviceUUID = uuidv4();
    localStorage.setItem(LOCALSTORAGE_DEVICE_UUID_NAME, `${deviceUUID}`);
  }
}

export const googleAnalyticsConfig: string = process.env.REACT_APP_CRM_GA_MEASUREMENT_ID as string;
