import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { api } from '../../utils/axios';
import { store } from '../store';

const devicesEndpoints = {
  devices: '/places-devices',
};

type DevicesStates = {
  isRequestingDevices: boolean;
  errorMessageDevices: string;
  devices: any;
  isRequestingDevice: boolean;
  errorMessageDevice: string;
  device: any;
  _order: string;
  _end: number;
  _start: number;
  headerCount: number;
  updated: boolean;
  resetPagination: boolean;
};

const initialState: DevicesStates = {
  isRequestingDevices: false,
  errorMessageDevices: '',
  devices: [],
  isRequestingDevice: false,
  errorMessageDevice: '',
  device: {},
  _order: 'DESC',
  _end: 10,
  _start: 0,
  headerCount: 0,
  updated: false,
  resetPagination: false,
};

const slice = createSlice({
  name: 'apikeys',
  initialState,
  reducers: {
    requestingDevices(state) {
      state.isRequestingDevices = true;
    },
    getDevicesSuccess(state, action) {
      state.isRequestingDevices = false;
      state.devices = action.payload;
    },
    getDevicesFail(state, action) {
      state.isRequestingDevices = false;
      state.errorMessageDevices = action.payload;
    },
    requestingDevice(state) {
      state.isRequestingDevice = true;
    },
    getDeviceSuccess(state, action) {
      state.isRequestingDevice = false;
      state.device = action.payload;
    },
    getDeviceFail(state, action) {
      state.isRequestingDevice = false;
      state.errorMessageDevice = action.payload;
    },
    getOrder(state, action) {
      state._order = action.payload;
    },
    getPaginationStartAndEnd(state, action) {
      const { start, end } = action.payload;
      state._end = end;
      start._start = start;
    },
    getDevicesHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
    resetDevice(state) {
      state.device = {};
    },
  },
});

export default slice.reducer;

export function getDevicesByPlace({
  _end,
  _order,
  _sort,
  _start,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingDevices());

    try {
      const queryStrings: any = {
        _end,
        _order,
        _sort,
        _start,
      };

      const response: void | AxiosResponse<any> = await api.get(devicesEndpoints.devices, {
        params: queryStrings,
      });

      const devices = response?.data;

      dispatch(slice.actions.getDevicesSuccess(devices));
      dispatch(slice.actions.getDevicesHeaders(Number(response?.headers['x-total-count'])));
    } catch (error) {
      dispatch(slice.actions.getDevicesFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function getDeviceById(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingDevice());

    try {
      const response: void | AxiosResponse<any> = await api.get(`${devicesEndpoints.devices}/${id}`);
      const device = response?.data;
      dispatch(slice.actions.getDeviceSuccess(device));
    } catch (error) {
      dispatch(slice.actions.getDeviceFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.getOrder(order));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}

export function resetDeviceData() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetDevice());
  };
}
