import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { FormClient } from '../../@types/client';
import { store } from '../store';
import { api } from '../../utils/axios';

const clientsEndpoints = {
  users: '/users',
};

type ClientsStates = {
  isRequestingClients: boolean;
  errorMessageClients: string;
  isLoading: boolean;
  successSave: boolean;
  error: boolean;
  count: number;
  totalCount: number;
  clients: any;
  client: any;
  _order: string;
  _end: number;
  _start: number;
  resetPagination: boolean;
  name: string;
};

const initialState: ClientsStates = {
  isRequestingClients: false,
  errorMessageClients: '',
  isLoading: false,
  successSave: false,
  error: false,
  count: 0,
  totalCount: 0,
  clients: [],
  client: [],
  _order: 'DESC',
  _end: 10,
  _start: 0,
  resetPagination: false,
  name: '',
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    requestingClients(state) {
      state.isRequestingClients = true;
    },
    getClientsSuccess(state, action) {
      state.isRequestingClients = false;
      state.clients = action.payload;
    },
    getClientsFail(state, action) {
      state.isRequestingClients = false;
      state.errorMessageClients = action.payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClientsByIdSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },
    getTransactionsHeaders(state, action) {
      state.totalCount = action.payload;
    },
    successSaveInvite(state, action) {
      state.isLoading = false;
      state.successSave = action.payload;
    },
    getOrder(state, action) {
      state._order = action.payload;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
    getFilters(state, action) {
      state.name = action.payload;
    },
    resetAllStates(state) {
      state.client = [];
      state.clients = [];
      state.name = '';
    },
  },
});

export default slice.reducer;

export function getRegisteredCustomers({
  _end,
  _order,
  _sort,
  _start,
  q,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  q: string;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingClients());
    dispatch(slice.actions.resetPagination(false));

    try {
      const queryStrings: any = {
        _end,
        _order,
        _sort,
        _start,
      };
      const minLength = 1;
      q.length > minLength && (queryStrings.q = q);

      const response: void | AxiosResponse<any> = await api.get(clientsEndpoints.users, {
        params: queryStrings,
      });

      dispatch(slice.actions.getClientsSuccess(response?.data));
      dispatch(slice.actions.getTransactionsHeaders(Number(response?.headers['x-total-count'])));
    } catch (error) {
      dispatch(slice.actions.getClientsFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function saveInviteUser(formData: FormClient) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading);
    try {
      const url = `/users`;

      const { data } = await api.post(url, formData);

      if (data) {
        dispatch(slice.actions.successSaveInvite(true));
      }
    } catch (error) {
      dispatch(slice.actions.hasError);
    }
  };
}

export function getClientById(id: string) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading);
    try {
      const url = `/users/${id}`;

      const { data } = await api.get(url);

      if (data) {
        dispatch(slice.actions.getClientsByIdSuccess(data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError);
    }
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.getOrder(order));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}

export function updateFilterOptions(name: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getFilters(name));
  };
}

export function resetClientsStates() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetAllStates());
  };
}
