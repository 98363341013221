import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  chip: {
    fontWeight: 300,
    paddingRight: '4px',
    paddingLeft: '4px',
    color: '#fff',
  },
  postPaidChip: {
    backgroundColor: '#AA00FF',
  },
  prePaidChip: {
    backgroundColor: '#00838F',
  },
  icon: {
    color: '#fff !important',
  },
}));

export function CardsCreditType({ paymentType }: any) {
  const classes = useStyles();

  const renderLabel = () => {
    if (paymentType && paymentType === 'POST_PAID') return 'Pós-pago';
    return 'Pré-pago';
  };

  return (
    <Chip
      className={`${classes.chip} ${
        paymentType && paymentType === 'POST_PAID' ? classes.postPaidChip : classes.prePaidChip
      }`}
      sx={{ mx: 0.75, my: 0.75 }}
      size="small"
      variant="outlined"
      icon={<CreditCardIcon className={classes.icon} />}
      label={renderLabel()}
    />
  );
}
