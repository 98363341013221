import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Typography,
  Skeleton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateDirectionOption, getTransactions } from '../../redux/slices/transactions';
import { RootState } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import StatusChip from './StatusChip';

type ParamTypes = {
  id: string;
};

export function TransactionsDataList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);
  const {
    period,
    _order,
    transactions,
    status,
    flag: authorizer,
    product,
    headerCount,
    resetPagination,
    isRequestingTransactions,
    columns,
    authorizersProducts,
  } = useSelector((state: RootState) => state.transactions);
  const minArrayLength = 1;

  const personalizeValue = (status, value) => {
    if (status === 'SUCCESS')
      return (
        <Typography color="primary" component="span" variant="body2">
          {value}
        </Typography>
      );
    if (status === 'CANCELED')
      return (
        <Typography sx={{ textDecoration: 'line-through' }} color="error" component="span" variant="body2">
          {value}
        </Typography>
      );
    if (status === 'FAIL')
      return (
        <Typography sx={{ color: 'rgb(255, 193, 7)', textDecoration: 'line-through' }} component="span" variant="body2">
          {value}
        </Typography>
      );
    return (
      <Typography sx={{ color: 'rgb(126, 126, 126)', textDecoration: 'line-through' }} component="span" variant="body2">
        {value}
      </Typography>
    );
  };

  const replaceByProductInstallmentsPlan = (
    authorizerData: any,
    authorizer: string,
    productName: string,
    installments: string
  ) => {
    const transactionAuthorizer = authorizerData?.find((data: any) => data?.name === authorizer);
    if (!transactionAuthorizer) return installments;
    if (
      transactionAuthorizer?.product?.name === productName &&
      transactionAuthorizer?.product?.metadata?.transactionData?.installmentsPlan &&
      transactionAuthorizer?.product?.metadata?.transactionData?.installmentsPlan[installments]
    ) {
      return (
        transactionAuthorizer?.product?.metadata?.transactionData?.installmentsPlan[installments]?.desc ||
        transactionAuthorizer?.product?.metadata?.transactionData?.installmentsPlan[installments]
      );
    }
    return installments;
  };

  const transactionsData = isRequestingTransactions
    ? [...Array(initialRows)].map(() => ({
        date: <Skeleton animation="wave" variant="rectangular" width={170} height={30} />,
        client: <Skeleton animation="wave" variant="rectangular" width={250} height={30} />,
        value: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        installments: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
        flag: <Skeleton animation="wave" variant="rectangular" width={60} height={30} />,
        product: <Skeleton animation="wave" variant="rectangular" width={60} height={30} />,
        status: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
      }))
    : transactions.map((row) => {
        const date = moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss');
        const client = `${row.firstName} ${row.lastName}`;
        return {
          id: row.id,
          date,
          client,
          value: personalizeValue(row.status, row.amount),
          installments: replaceByProductInstallmentsPlan(
            authorizersProducts,
            row.cardBrand,
            row.cardProduct,
            row.installments
          ),
          flag: row.cardBrand,
          product: row.cardProduct,
          status: <StatusChip status={row.status} />,
        };
      });

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = () => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
  };

  useEffect(() => {
    if (resetPagination === true) {
      setPage(initialPage);
      setRowsPerPage(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPagination]);

  useEffect(() => {
    const _sort = 'createdAt';
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(
        getTransactions({
          _end: end,
          _order,
          _sort,
          _start: start,
          period,
          authorizer,
          product,
          status,
          user: id,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleClick = (_event, id) => {
    history.push(`${PATH_DASHBOARD.general.app}/${id}`);
  };
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 1 }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {transactionsData.length < minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.label === 'Data' ? (
                  <TableCell key={column.id} style={{ width: column.width, cursor: 'pointer' }}>
                    {column.label}
                    <TableSortLabel
                      active={true}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={handleDirection}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsData.map((row, index) => (
              <TableRow style={{ cursor: 'pointer' }} hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column) => {
                  const value = row[column.id] || '-';
                  return (
                    <TableCell key={column.id} onClick={(event) => handleClick(event, row.id)}>
                      {column.format && typeof value === 'number' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
